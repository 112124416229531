import * as React from "react";

import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';

import HighlightsCard from "../../components/HighlightsCard";
import LandingIntroSidebarCard from "../../components/LandingIntroSidebarCard";

import { TransitionGroup } from 'react-transition-group';
import { getRandomHighlights } from "../../api/resources/utilities";

interface RenderItemOptions {
  title: string;
  author: string;
  highlight: string;
  likes: string;
}

const getBookImage = (title, bookMetadata) => {
  if (bookMetadata) {
    const bookItem = bookMetadata.find((item) => item.title === title);

    if (bookItem) {
      return bookItem.image;
    }

    return null;
  }

  return null;
}

function renderItem( { title, author, highlight, likes } : RenderItemOptions, bookMetadata) {
  return (
    <ListItem>
      <HighlightsCard
        isUnauth
        emptyLikes={false}
        author={author}
        highlights={highlight}
        title={title}
        image={getBookImage(title, bookMetadata)}
        likers={likes}
      />
    </ListItem>
  );
}

const LandingPage = () => {
  const navigate = useNavigate();

  const [randomHighlights, setRandomHighlights] = React.useState(null);

  React.useEffect(() => {
    try {
      const getRandomHighlightsRequest = async () => {
        const res = await getRandomHighlights();

        setRandomHighlights(res);
      };

      getRandomHighlightsRequest();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Grid container spacing={1} direction="column-reverse" sx={{ flexDirection: { xs: "column-reverse", lg: "row" }, maxWidth: { lg: "1920px" }, margin: "0 auto" }}>
      <Grid item xs={12} lg={8}>
        <Box>
          <List>
            <TransitionGroup>
              {
                randomHighlights && randomHighlights.cards ? (
                  randomHighlights.cards.map((item, index) => (
                    <Collapse key={item._id}>
                      {
                        renderItem({
                          title: item.title,
                          author: item.author,
                          highlight: item.highlights,
                          likes: randomHighlights.likes[index]
                        }, randomHighlights.bookMetadata)
                      }
                    </Collapse>
                  ))
                ) : null
              }
            </TransitionGroup>
          </List>
        </Box>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Box sx={{ position: { lg: "fixed" }, width: "100%", maxWidth: { xs: "100%", lg: "30%" } }}>
          <LandingIntroSidebarCard />
        </Box>
      </Grid>
    </Grid>
  );
};

export default LandingPage;
