import { axiosClient } from "../apiClient";

export async function getHighlights(uid) {
  try {
    const res = await axiosClient.get("/getHighlights", { params: { uid: uid } });
    const res2 = await axiosClient.get("/getHighlights2", { params: { uid: uid } });

    let data = {...res.data, ...res2.data};

    return data;
  } catch (error) {
    return [];
  }
}


export async function getPublicHighlights(uid, publicUid) {
  try {
    const res = await axiosClient.get(`/getHighlights/${publicUid}`, { params: { uid: uid } });
    const res2 = await axiosClient.get(`/getHighlights2/${publicUid}`, { params: { uid: uid } });
    const res3 = await axiosClient.get(`/getHighlights3/${publicUid}`, { params: { uid: uid } });

    let data = {...res.data, ...res2.data, ...res3.data};

    return data;
  } catch (error) {
    return [];
  }
}


export async function getFollowingHighlights(uid) {
  try {
    const res = await axiosClient.get("/getFollowingHighlights", { params: { uid: uid } });

    let data = res.data;

    return data;
  } catch (error) {
    return [];
  }
}


export async function getHighlightsCount(uid) {
  try {
    const res = await axiosClient.get("/getHighlightsCount", { params: { uid: uid } });

    let data = res.data;

    return data;
  } catch (error) {
    return [];
  }
}


export async function getPublicHighlightsCount(uid, publicUid) {
  try {
    const res = await axiosClient.get(`/getHighlightsCount/${publicUid}`, { params: { uid: uid } });

    let data = res.data;

    return data;
  } catch (error) {
    return [];
  }
}


export async function putHighlightLike(uid, highlightId, highlightOwnerUid) {
  try {
    const res = await axiosClient.put("/like", { params: { uid: uid, highlightId: highlightId, ouid: highlightOwnerUid } });

    let data = res.data;

    return data;
  } catch (error) {
    return [];
  }
}


export async function putHighlightUnlike(uid, highlightId) {
  try {
    const res = await axiosClient.put("/unlike", { params: { uid: uid, highlightId: highlightId } });

    let data = res.data;

    return data;
  } catch (error) {
    return [];
  }
}


export async function putLibraryFollow(uid, userId) {
  try {
    const res = await axiosClient.put(`/follow`, { params: { id: uid, followID: userId } });

    let data = res.data;

    return data;
  } catch (error) {
    return [];
  }
}


export async function putLibraryUnfollow(uid, userId) {
  try {
    const res = await axiosClient.put(`/unfollow`, { params: { id: uid, followID: userId } });

    let data = res.data;

    return data;
  } catch (error) {
    return [];
  }
}


export async function postHighlights(uid, title, author, highlights) {
  try {
    const res = await axiosClient.post("/manualimport", { uid, title, author, highlights } );

    return true;
  } catch (error) {
    return false;
  }
}


export async function deleteHighlights(highlightId) {
  try {
    const res = await axiosClient.delete("/deletehighlights", { params: { id: highlightId } } );

    return true;
  } catch (error) {
    return false;
  }
}


