import * as React from "react";
import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import PersonRemoveAlt1Icon from "@mui/icons-material/PersonRemoveAlt1";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";

import BookCard from "../../../components/BookCard";
import LoadingIndicator from "../../../components/LoadingIndicator";
import FullPageOnboardNotice from "../../../components/FullPageNotice_Onboard";
import FollowerCounts from "../../../components/FollowerCounts";
import LoginDialog from "../../../components/LoginDialog";

import { AuthContext } from "../../../contexts/authProvider";
import { LibraryContext } from "../../../contexts/libraryProvider";
import { PublicLibraryContext } from "../../../contexts/publicLibraryProvider";

import { getPublicUserdata } from "../../../api/resources/user";
import {
  getHighlightsCount,
  getPublicHighlightsCount,
  getPublicHighlights,
  putLibraryFollow,
  putLibraryUnfollow,
} from "../../../api/resources/library";

import { getUid } from "../../../processes/user";

export default function AllBooks(props) {
  const { otherUsers } = props;
  let params = useParams();

  const [followBtnLoading, setFollowBtnLoading] = React.useState(false);
  const [loginDialogOpen, setLoginDialogOpen] = React.useState(false);

  const { library, handleSetHighlightCounts } =
    React.useContext(LibraryContext);

  const {
    profileUid,
    userdata,
    highlights,
    highlightsCount,
    handleSetUserdata,
    handleSetHighlights,
    handleSetHighlightsCount,
    handleResetPublicLibrary,
    handleSetProfileUid,
  } = React.useContext(PublicLibraryContext);

  const { user } = React.useContext(AuthContext);

  const uid = getUid(user.firebaseData);

  // BEGIN otherUsers useEffects
  React.useEffect(() => {
    try {
      if (otherUsers && profileUid !== params.userId) {
        handleResetPublicLibrary();
        handleSetProfileUid(params.userId);
      }
    } catch (error) {
      console.log(error);
    }
  }, [params]);

  // Get user details
  React.useEffect(() => {
    try {
      const getUserdataRequest = async () => {
        if (otherUsers && userdata === null) {
          const res = await getPublicUserdata(uid, profileUid);

          handleSetUserdata(res);
        }
      };
    } catch (error) {
      console.log(error);
    }
  }, [profileUid]);

  // Get user highlights
  React.useEffect(() => {
    try {
      const getHighlightsRequest = async () => {
        if (otherUsers && highlights === null) {
          const res = await getPublicHighlights(uid, profileUid);

          handleSetHighlights(res);
        }
      };

      getHighlightsRequest();
    } catch (error) {
      console.log(error);
    }
  }, [profileUid]);

  // Get user highlightsCount
  React.useEffect(() => {
    try {
      const getHighlightCountRequest = async () => {
        if (otherUsers && highlightsCount === null) {
          const res = await getPublicHighlightsCount(uid, profileUid);

          handleSetHighlightsCount(res);
        }
      };

      getHighlightCountRequest();
    } catch (error) {
      console.log(error);
    }
  }, [profileUid]);

  // BEGIN logged in users useEffects
  React.useEffect(() => {
    try {
      const getHighlightsRequest = async () => {
        if (!otherUsers && library.highlightCounts === null) {
          const res = await getHighlightsCount(uid);

          handleSetHighlightCounts(res.libraryData);
        }
      };

      getHighlightsRequest();
    } catch (error) {
      console.log(error);
    }
  }, [library]);

  const getUserName = () => {
    if (userdata && userdata.userinfo && userdata.userinfo.length > 0) {
      return userdata.userinfo[0]["firstName"];
    }

    return "The Nameless";
  };

  const handleUnfollow = () => {
    const putUnfollow = async () => {
      await putLibraryUnfollow(uid, params.userId);
      const resUdata = await getPublicUserdata(uid, profileUid);

      handleSetUserdata(resUdata);

      setFollowBtnLoading(false);
    };

    setFollowBtnLoading(true);
    putUnfollow();
  };

  const handleFollow = () => {
    const putFollow = async () => {
      await putLibraryFollow(uid, params.userId);
      const resUdata = await getPublicUserdata(uid, profileUid);

      handleSetUserdata(resUdata);

      setFollowBtnLoading(false);
    };

    if (uid) {
      setFollowBtnLoading(true);
      putFollow();
    } else {
      setLoginDialogOpen(true);
    }
  };

  const getFollowButton = () => {
    let isFollowing = false;

    if (userdata && userdata.userinfo && userdata.userinfo.length > 0) {
      if (
        Object.prototype.hasOwnProperty.call(userdata.userinfo[0], "followers")
      ) {
        isFollowing = userdata.userinfo[0]["followers"].find(
          (elem) => elem === uid,
        );
      }
    }

    return (
      <LoadingButton
        variant="outlined"
        startIcon={
          isFollowing ? <PersonRemoveAlt1Icon /> : <PersonAddAlt1Icon />
        }
        color={isFollowing ? "error" : "primary"}
        onClick={() => (isFollowing ? handleUnfollow() : handleFollow())}
        loading={followBtnLoading}
      >
        {isFollowing ? "Unfollow" : "Follow"}
      </LoadingButton>
    );
  };

  const getHeader = () => {
    if (otherUsers) {
      return (
        <Grid
          container
          spacing={1}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} lg={5}>
            <Typography variant="h3">{`${getUserName()}'s Library`}</Typography>
            <Typography variant="body1">
              Herein lies a collection of books that {`${getUserName()}`} has
              read so far
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              marginTop: { xs: "0.8rem", lg: "unset" },
              justifyContent: { lg: "flex-end" },
            }}
            xs={12}
            lg={7}
          >
            <FollowerCounts userdata={userdata} profileUid={profileUid} />
            {getFollowButton()}
          </Grid>
        </Grid>
      );
    }

    return (
      <Box>
        <Typography variant="h3">Library</Typography>
        <Typography paragraph>
          A collection of books you have added to Blipps so far
        </Typography>
      </Box>
    );
  };

  const getCards = () => {
    if (otherUsers) {
      if (
        highlightsCount &&
        highlightsCount.libraryData &&
        highlightsCount.libraryData.length !== 0
      ) {
        return highlightsCount.libraryData.map((item) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              sx={{ display: "flex" }}
              key={item["title"]}
            >
              <BookCard
                title={item["title"]}
                image={item["image"]}
                author={item["author"]}
                highlightsCount={item["userhighlightsCount"]}
                asin={item["asin"]}
              />
            </Grid>
          );
        });
      }
    } else {
      if (library.highlightCounts && library.highlightCounts.length !== 0) {
        return library.highlightCounts.map((item) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              sx={{ display: "flex" }}
              key={item["title"]}
            >
              <BookCard
                title={item["title"]}
                image={item["image"]}
                author={item["author"]}
                highlightsCount={item["userhighlightsCount"]}
                asin={item["asin"]}
              />
            </Grid>
          );
        });
      }
    }

    return null;
  };

  const getContent = () => {
    if (otherUsers) {
      if (
        highlightsCount &&
        highlightsCount.libraryData &&
        highlightsCount.libraryData.length !== 0
      ) {
        return (
          <Grid container spacing={2}>
            {getCards()}
          </Grid>
        );
      } else if (
        highlightsCount &&
        highlightsCount.libraryData &&
        highlightsCount.libraryData.length === 0
      ) {
        return (
          <Grid container spacing={2} sx={{ margin: "0 auto" }}>
            <Box sx={{ textAlign: "center", width: "100%", margin: "20rem" }}>
              <Typography variant="h3">An empty library</Typography>
              <Typography variant="body1" sx={{ fontStyle: "italic" }}>
                This library does not have anything yet
              </Typography>
            </Box>
          </Grid>
        );
      }
    } else {
      if (library.highlightCounts && library.highlightCounts.length !== 0) {
        return (
          <Grid container spacing={2}>
            {getCards()}
          </Grid>
        );
      }

      if (
        Array.isArray(library.highlightCounts) &&
        library.highlightCounts.length === 0
      ) {
        return <FullPageOnboardNotice uid={uid} />;
      }
    }

    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "300px" }}>
          <LoadingIndicator />
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box>
        {getHeader()}
        <Divider sx={{ margin: "1rem 0" }} />
        {getContent()}
      </Box>
      <LoginDialog
        isOpen={loginDialogOpen}
        handleClose={() => setLoginDialogOpen(false)}
      />
    </>
  );
}
