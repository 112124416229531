import * as React from "react";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";

import { LibraryContext } from "../../contexts/libraryProvider";
import { AuthContext } from "../../contexts/authProvider";

import FullPageNoFavourites from "../../components/FullPageNotice_NoFavourites";
import HighlightsCard from "../../components/HighlightsCard";
import FavouriteCard from "../../components/FavouriteCard";

import {
  getHighlights,
} from "../../api/resources/library";

const PrintHandler = (props) => {
  const componentRef = React.useRef();

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  const [randomNumber, setRandomNumber] = React.useState(getRandomInt(47));
  const [showDownload, setShowDownload] = React.useState(false);

  const handleDownloadImage = async () => {
    const element = componentRef.current;
    const canvas = await html2canvas(element, {
      backgroundColor: "none",
      logging: true,
      windowWidth: 1920,
      windowHeight: 1080,
      useCORS: true, //to enable cross origin perms
    });

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      console.log("");
      link.download = "HighlightCard";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        boxShadow: 3,
        borderRadius: "20px",
      }}
    >
      <Box
        onMouseOver={() => setShowDownload(true)}
        onMouseOut={() => setShowDownload(false)}
        sx={{
          zIndex: "1",
          height: "100%",
          width: "100%",
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "end",
        }}
      >
        {showDownload ? (
          <>
            <IconButton
              size="large"
              sx={{ fontSize: "3em" }}
              onClick={handleDownloadImage}
            >
              <DownloadRoundedIcon
                sx={{ fill: "white", fontSize: "inherit", mt: 0.375 }}
              />
            </IconButton>
            <IconButton
              size="large"
              sx={{ fontSize: "3em" }}
              onClick={() => setRandomNumber(getRandomInt(46))}
            >
              <CachedRoundedIcon
                sx={{ fill: "white", fontSize: "inherit", mt: 0.375 }}
              />
            </IconButton>
          </>
        ) : null}
      </Box>
      <FavouriteCard
        ref={componentRef}
        randomNumber={randomNumber}
        {...props}
      />
    </Box>
  );
};

const Gallery = () => {
  const [data, setData] = React.useState({ email: "", password: "" });
  const [error, setError] = React.useState("");
  const [activeView, setActiveView] = React.useState("list");
  const navigate = useNavigate();

  const { user, handleSetUserDetails } = React.useContext(AuthContext);
  const {
    library,
    followingLibrary,
    handleSetLibrary,
    handleSetFollowingLibrary,
  } = React.useContext(LibraryContext);
  const uid = user.firebaseData.auth.currentUser.uid;

  React.useEffect(() => {
    try {
      const getHighlightsRequest = async () => {
        if (library.favourites === null) {
          const res = await getHighlights(uid);

          const parsedMetadata = {};

          res["bookMetadata"].forEach((item) => {
            if (!item) {
              return;
            }

            parsedMetadata[item.title] = {
              image: item.image,
            };
          });

          handleSetLibrary(
            res.cards,
            parsedMetadata,
            res.likersMetadata,
            res.FavHighlights,
          );
        }
      };

      getHighlightsRequest();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: string,
  ) => {
    setActiveView(newView);
  };

  const control = {
    value: activeView,
    onChange: handleViewChange,
    exclusive: true,
  };

  const getFavouritesAsCards = () => {
    if (library && library.favourites) {
      if (library.favourites.length > 0) {
        return library.favourites.map((item) => {
          return (
            <Grid item xs={12} md={6} lg={4}>
              <PrintHandler
                highlight={item.highlights}
                author={item.author}
                title={item.title}
              />
            </Grid>
          );
        });
      }
    }

    return <FullPageNoFavourites />;
  };

  const getBookImage = (title) => {
    if (library && library.books) {
      if (Object.prototype.hasOwnProperty.call(library.books, title)) {
        if (
          Object.prototype.hasOwnProperty.call(library.books[title], "image")
        ) {
          return library.books[title]["image"];
        }
      }
    }

    return null;
  };

  const getName = () => {
    if (user.userDetails) {
      if (Object.prototype.hasOwnProperty.call(user.userDetails, "userinfo")) {
        if (user.userDetails.userinfo.length > 0) {
          return (
            user.userDetails["userinfo"][0]["firstName"] +
            " " +
            user.userDetails["userinfo"][0]["lastName"]
          );
        }

        return "The Nameless";
      }
    }
  };

  const getFavouritesAsList = () => {
    if (library && library.favourites) {
      if (library.favourites.length > 0) {
        return library.favourites.map((item) => {
          return (
            <Grid item xs={12}>
              <HighlightsCard
                isGalleryView
                activeUid={uid}
                highlightId={item._id}
                author={item.author}
                highlights={item.highlights}
                title={item.title}
                image={getBookImage(item.title)}
                firstName={getName()}
                highlightCreatorUserId={item.uid}
                isHighlightCreator={item.uid === uid}
                // creatorUid={item.uid}
                likers={item.likes}
                likerProfiles={library.likersMetadata}
              />
            </Grid>
          );
        });
      }
    }

    return <FullPageNoFavourites />;
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h3">Your Favourite Quotes</Typography>
          <Typography paragraph>
            A collection of highlights you have hearted
          </Typography>
          <Divider sx={{ margin: "1rem 0" }} />
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                paddingBottom: "1rem",
              }}
            >
              <ToggleButtonGroup
                size="large"
                {...control}
                aria-label="Large sizes"
              >
                <ToggleButton value="list" key="left">
                  <ViewListIcon />
                </ToggleButton>
                <ToggleButton value="cards" key="center">
                  <ViewModuleIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Grid>
          {activeView === "list"
            ? getFavouritesAsList()
            : getFavouritesAsCards()}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Gallery;
