import * as React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import Avatar from "@mui/material/Avatar";

import { AuthContext } from "../../contexts/authProvider";

import CommentItems from "./CommentItems";
import CommentComposer from "./CommentComposer";

import { getComments, postComment, postChildComment } from "../../api/resources/bookClub";

export default function BookDiscussions(props) {
  const { bookId, activeUid } = props;

  const { user } = React.useContext(AuthContext);

  const getName = () => {
    if (user.userDetails) {
      if (Object.prototype.hasOwnProperty.call(user.userDetails, "userinfo")) {
        if (user.userDetails.userinfo.length > 0) {
          let firstName = user.userDetails["userinfo"][0]["firstName"];
          let lastName = user.userDetails["userinfo"][0]["lastName"];

          if (firstName) {
            firstName = firstName.trim();
          } else {
            firstName = "";
          }

          if (lastName) {
            lastName = lastName.trim();
          } else {
            lastName = "";
          }

          return firstName + " " + lastName;
        }
      }
    }
    return "The Nameless";
  };

  const getUserImage = () => {
    function stringToColor(string: string) {
      let hash = 0;
      let i;

      /* eslint-disable no-bitwise */
      for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }

      let color = "#";

      for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
      }
      /* eslint-enable no-bitwise */

      return color;
    }

    const stringAvatar = (name: string) => {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
      };
    };

    if (user.userDetails) {
      if (Object.prototype.hasOwnProperty.call(user.userDetails, "userinfo")) {
        if (
          user.userDetails["userinfo"] &&
          user.userDetails["userinfo"].length > 0 &&
          user.userDetails["userinfo"][0]["img"]) {
          if (
            user.userDetails["userinfo"][0]["img"]["contentType"].includes(
              "image/",
            )
          ) {
            const imageDetails = user.userDetails["userinfo"][0]["img"];

            return (
              <Avatar alt={getName()} src={`data:${imageDetails.contentType};base64, ${imageDetails.img}`} />
            );
          }
        }
      }
    }

    return <Avatar {...stringAvatar(getName())} />;
  };

  const [comments, setComments] = React.useState(null);
  const [userProfile, setUserProfile] = React.useState(null);
  const [currentBookId, setCurrentBookId] = React.useState('');

  const getCommentsRequest = async () => {
    const res = await getComments(bookId);

    setComments(res.comments);
    setUserProfile(res.userProfile);
  }

  React.useEffect(() => {

    if (!comments) {
      getCommentsRequest();
    } else if (currentBookId !== bookId) {
      setComments(null);
      setUserProfile(null);

      getCommentsRequest();
    }

    setCurrentBookId(bookId);
  }, [bookId, activeUid]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <CommentItems comments={comments} activeUid={activeUid} userProfile={userProfile} userImageComponent={getUserImage()} bookId={currentBookId} getCommentsRequest={getCommentsRequest} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <CommentComposer userImageComponent={getUserImage()} activeUid={activeUid} bookId={currentBookId} getCommentsRequest={getCommentsRequest} />
      </Grid>
    </Grid>
  );
}
