import * as React from "react";
import { useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

import logo from "../../assets/img/logos/logo.png";

function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  window.gtag('event', 'conversion', {
      'send_to': 'AW-11040354133/F9lACKD0vYYYENXeuZAp',
      'event_callback': callback
  });
  window.gtag('event', 'conversion', {
    'send_to': 'AW-11040354133/kZsLCOaTv4YYENXeuZAp',
    'event_callback': callback
  });
  return false;
}

export default function LandingIntroSidebarCard(props) {
  let navigate = useNavigate();

  return (
    <Card
      raised
      variant="outlined"
      sx={{ borderRadius: "20px", boxShadow: 20, display: "flex", marginTop: "1rem" }}
    >
      <CardContent sx={{ width: "100%" }}>
        <Grid spacing={1} container>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", paddingBottom: "1rem" }}>
              <Box
                component="img"
                src={logo}
                sx={{ width: "4.5rem", borderRadius: "50%", marginRight: "1rem" }}
              />
              <Typography variant="h1" sx={{ fontSize: "2.5rem" }}>Blipps.io</Typography>
            </Box>

            <List dense={false}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <DriveFileRenameOutlineIcon />
                  </Avatar>
                </ListItemAvatar>
                <Box>
                  <Typography variant="h2" sx={{ fontSize: "1.5rem" }}>Keep track of highlights</Typography>
                  <Typography gutterBottom variant="body2">Record your book highlights and view them in a gorgeous library on Blipps. You can also import highlights from your Kindle or Kobo.</Typography>
                </Box>
              </ListItem>
              
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <MenuBookIcon />
                  </Avatar>
                </ListItemAvatar>
                <Box>
                  <Typography variant="h2" sx={{ fontSize: "1.5rem" }}>Your reading journey at a glance</Typography>
                  <Typography gutterBottom variant="body2">When you populate highlights within your Blipps, your books and highlights are showcased with their beautiful book covers.</Typography>
                </Box>
              </ListItem>
              
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <FormatQuoteIcon />
                  </Avatar>
                </ListItemAvatar>
                <Box>
                  <Typography variant="h2" sx={{ fontSize: "1.5rem" }}>Quote sharing</Typography>
                  <Typography gutterBottom variant="body2">Blipps can generate some visually stunning images of the highlights you love, ready to share or even use as your device wallpaper!</Typography>
                </Box>
              </ListItem>
            </List>
            <Box sx={{ paddingTop: "28px" }}>
              <Button
                fullWidth
                size="large"
                variant="contained"
                endIcon={<NavigateNextIcon />}
                onClick={() => {
                  gtag_report_conversion('/login');

                  navigate('login');
                }}
              >
                Get started with Blipps
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ padding: "3.3rem 0 1rem 0", textAlign: "center" }}>
          <a href="https://www.producthunt.com/posts/blipps-io?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-blipps&#0045;io" target="_blank">
            <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=364425&theme=neutral" alt="Blipps.io - Bringing about a fresh change to the book social-media space | Product Hunt" />
          </a>
        </Box>
      </CardContent>
    </Card>
  );
}
