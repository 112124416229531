import * as React from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import LoadingButton from '@mui/lab/LoadingButton';

import { postUserRegistration } from "../../api/resources/user";

export default function SignupForm() {
  const navigate = useNavigate();

  const [formLoading, setFormLoading] = React.useState(false);
  const [data, setData] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    uid: "",
  });
  const [error, setError] = React.useState("");

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLInputElement>,
  ): Promise<void> => {
    e.preventDefault();
    const auth = getAuth();
    setFormLoading(true);

    createUserWithEmailAndPassword(auth, data.email, data.password)
      .then(async (userCredential) => {
        // Signed in
        const uid = userCredential.user.uid;
        data.uid = uid;
        const user = userCredential.user;
        const res = await postUserRegistration(data);
        //navigate("/login");
        //console.log(res.message);
        navigate("/feed");
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        if (error.code.includes("auth/email-already-in-use")) {
          setError("The email is already in use.");
        } else if (error.code.includes("auth/weak-password")) {
          setError("Please use a password of at least six characters.");
        }
        setFormLoading(false);
      });
  };

  return (
    <>
      <Grid item xs={12}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ display: "flex", flexDirection: "column", paddingTop: "2rem" }}
        >
          <TextField
            variant="outlined"
            type="text"
            placeholder="First Name"
            name="firstName"
            onChange={handleChange}
            value={data.firstName}
            required
            sx={{ paddingBottom: "1rem" }}
          />
          <TextField
            variant="outlined"
            type="text"
            placeholder="Last Name"
            name="lastName"
            onChange={handleChange}
            value={data.lastName}
            required
            sx={{ paddingBottom: "1rem" }}
          />
          <TextField
            variant="outlined"
            type="email"
            placeholder="Email"
            name="email"
            onChange={handleChange}
            value={data.email}
            required
            sx={{ paddingBottom: "1rem" }}
          />
          <TextField
            variant="outlined"
            type="password"
            placeholder="Password"
            name="password"
            onChange={handleChange}
            value={data.password}
            required
          />
          {error && <FormHelperText error>{error}</FormHelperText>}
          <Stack
            spacing={1}
            direction="row"
            sx={{ paddingTop: "2rem", margin: "0 auto" }}
          >
            <LoadingButton
              loading={formLoading}
              variant="contained"
              type="submit"
            >
              Sign Up
            </LoadingButton>
          </Stack>
        </Box>
      </Grid>
    </>
  );
}
