import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { FileUploader } from "react-drag-drop-files";
import { postImport } from "../../api/import";
import { AuthContext } from "../../contexts/authProvider";
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

import step2 from '../../assets/img/step2.PNG';
import step3_1 from '../../assets/img/step3_1.PNG';
import step3_2 from '../../assets/img/step3_2.png';

import KindleStepper from './KindleStepper';
import KoboStepper from './KoboStepper';

const drawerWidth = 240;


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const fileTypes = ["TXT"];
const steps = [
  {
    label: 'Connect Kindle to your computer',
    description: () => { },
  },
  {
    label: 'Go to your file explorer (Windows) or Finder (macOS)',
    description: () => {
      return (
        <Box>
          <Box component="img" src={step2} sx={{ border: "1px solid #555" }} />
        </Box>
      )
    },
  },
  {
    label: 'Access the “documents” folder and create a copy of “My Clippings”, saving it anywhere on your device.',
    description: () => {
      return (
        <Box>
          <Box component="img" src={step3_1} sx={{ width: "100%", border: "1px solid #555" }} />
          <Box component="img" src={step3_2} sx={{ width: "100%", border: "1px solid #555" }} />
        </Box>
      )
    },
  },
  {
    label: 'Click on the button below to browse for your file then start accessing highlights!',
    description: () => { },
  },
];


const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function ImportAccordion(props: Props) {
  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const [activeStep, setActiveStep] = React.useState(0);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "800px", margin: "3rem auto" }}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography>
            How do I get my highlights?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} variant="fullWidth">
              <Tab label="I use a Kindle" />
              <Tab label="I use a Kobo" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <KindleStepper />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <KoboStepper />
          </TabPanel>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
