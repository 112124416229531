import * as React from "react";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { useTheme } from '@mui/material/styles';
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import useMediaQuery from '@mui/material/useMediaQuery';

import Toolbar from "@mui/material/Toolbar";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { LibraryContext } from "../../contexts/libraryProvider";
import { AuthContext } from "../../contexts/authProvider";

import SidebarProfile from "./SidebarProfile";
import AppListItem from "./AppListItem";


const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

export default function AppDrawer(props: Props) {
  const { window, mobileOpen, handleDrawerToggle } = props;
  const navigate = useNavigate();
  const { handleResetLibrary } = React.useContext(LibraryContext);
  const { handleResetUser } = React.useContext(AuthContext);

  const utilLinks = [
    { route: "/import", name: "Import", icon: <CloudUploadIcon />, func: null },
    {
      route: "/settings",
      name: "Settings",
      icon: <SettingsIcon />,
      func: null,
    },
    {
      route: null,
      name: "Sign Out",
      icon: <LogoutIcon />,
      func: () => {
        const auth = getAuth();
        signOut(auth)
          .then(() => {
            // Sign-out successful.
            // Clear Library Context
            navigate("/");
            handleResetLibrary();
            handleResetUser();
          })
          .catch((error) => {
            console.log(error)
            // An error happened.
          });
      },
    },
  ];

  const drawer = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <Box>
        <Toolbar />
        <SidebarProfile />
      </Box>
      <Box>
        <Divider />
        <List>
          {utilLinks.map((item) => (
            <AppListItem
              key={item.name}
              to={item.route}
              icon={item.icon}
              name={item.name}
              func={item.func}
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const theme = useTheme();
  let isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? mobileOpen : true}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          // display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      {/* <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        open
      >
        {drawer}
      </Drawer> */}
    </Box>
  );
}
