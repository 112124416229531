import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Lottie from "lottie-react";
import empty from "./empty.json";

import FullPageNotice from "../../components/FullPageNotice";

export default function FullPageEmptyFollowingNotice(props) {

  return (
    <FullPageNotice imageSrc={null}>
      <Grid item xs={12} md={4}>
        <Lottie animationData={empty} loop={true} />
      </Grid>
      <Grid item xs={12} md={8} sx={{ margin: { xs: "1.5rem 0", md: "initial" } }}>
        <Box sx={{ display: "flex", alignContent: "center", justifyContent: "center", flexDirection: "column", height: "100%" }}>
          <Typography variant="h4">An empty list</Typography>
          <Typography variant="body1" sx={{ fontStyle: "italic" }}>Highlights from other Blipps users you follow will appear here!</Typography>
        </Box>
      </Grid>
    </FullPageNotice>
  );
}
