import * as React from 'react';

import Box from '@mui/material/Box';
import Avatar from "@mui/material/Avatar";

const getName = (firstName, lastName) => {
  let tempFn = "";
  let tempLn = "";

  if (firstName || lastName) {
    if (firstName) {
      tempFn = firstName.trim();
    } else {
      tempFn = "";
    }

    if (lastName) {
      tempLn = lastName.trim();
    } else {
      tempLn = "";
    }

    return tempFn + " " + tempLn;
  }

  return "The Nameless";
};

export default function UserImage(props) {
  const { firstName, lastName, imageMetadata, isLarge } = props;

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  
  const stringAvatar = (name: string) => {
    if (isLarge) {
      return {
        sx: {
          bgcolor: stringToColor(name),
          width: "6rem",
          height: "6rem",
          margin: "0 auto",
          fontSize: "2rem",
        },
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
      };
    }

    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  };

  if (imageMetadata) {
    if (
      Object.prototype.hasOwnProperty.call(imageMetadata, "contentType") &&
      Object.prototype.hasOwnProperty.call(imageMetadata, "img")
    ) {
      if (isLarge) {
        return (
          <Box
            component="img"
            src={`data:${imageMetadata.contentType};base64, ${imageMetadata.img}`}
            sx={{
              borderRadius: "50%",
              width: "6rem",
              height: "6rem",
            }}
          />
          );
        }
        
        return <Avatar alt={stringAvatar(getName(firstName, lastName))} src={`data:${imageMetadata.contentType};base64, ${imageMetadata.img}`} />;
    }
  }

  return <Avatar {...stringAvatar(getName(firstName, lastName))} />;
}