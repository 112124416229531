import { createTheme } from "@mui/material/styles";

// Create a theme instance.
const theme = createTheme({
  palette: {
    mode: "light",
    blippsWhite: {
      main: "#fff",
      contrastText: "#fff",
    },
    blippsGray: {
      main: "#808080",
      contrastText: "#fff",
    },
    background: {
      default: "#fafdff",
    },
  },
});

export default theme;
