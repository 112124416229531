import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';

import FollowerDialog from "../FollowerDialog";

import { getUserFollowing, getUserFollowers } from '../../api/resources/user';
import { getBookClub } from '../../api/resources/bookClub';

const FollowerCounts = (props) => {
  const { userdata, profileUid, hideBookClubs, noMargins } = props;

  const [followerDialogOpen, setFollowerDialogOpen] = React.useState("closed");
  const [followers, setFollowers] = React.useState(null);
  const [following, setFollowing] = React.useState(null);
  const [bookClub, setBookClub] = React.useState(null);

  // const handleExternalDialogState = (externalDialogState) => {
  //   if (externalDialogState) {
  //     setFollowerDialogOpen("bookclub");
  //   }
    
  //   setFollowerDialogOpen("closed");
  // }

  // handleExternalDialogState(externalDialogState);
  
  React.useEffect(() => {
    const getFollowers = async () => {
      const followerRes = await getUserFollowers(profileUid);
      const followingRes = await getUserFollowing(profileUid);
      const bookClubRes = await getBookClub(profileUid);

      setBookClub(bookClubRes);
      setFollowers(followerRes);
      setFollowing(followingRes);

      // if (!followers || followers.length === 0) {
  
      // }
      
      // if (!following || following.length === 0) {
  
      // }

      // if (!bookClub || bookClub.length === 0) {
  
      // }
    }

    getFollowers();
  }, [profileUid]);

  const getFollowerCount = () => {
    if (
      followers &&
      followers.length > 0
    ) {
      const tempArr = followers.filter((item) => item['userMetadata'][0] !== null);
      
      return tempArr.length;
    }
  
    return 0;
  }
  
  const getFollowingCount = () => {
    if (
      following &&
      following.length > 0
    ) {
      const tempArr = following.filter((item) => item['userMetadata'][0] !== null);
  
      return tempArr.length;
    }
  
    return 0;
  }
  
  const getBookclubs = () => {
    if (
      bookClub &&
      bookClub.length > 0
    ) {
      const tempArr = bookClub.filter((item) => item['bookMetadata'][0] !== null);

      return tempArr.length;
    }

    return 0;
  }

  return (
    <>
      <Box sx={{ display: "flex", padding: {xs: noMargins ? "unset" : "0 2rem 0 0", lg: "0 2rem"}, textAlign: "center" }}>
        <Box sx={{ cursor: "pointer", "&:hover": { textDecoration: "underline" } }} onClick={() => setFollowerDialogOpen("follower")}>
          <Typography sx={{ fontWeight: "lighter" }}>Followers</Typography>
          <Typography>{getFollowerCount()}</Typography>
        </Box>
        <Divider orientation="vertical" sx={{ margin: "0 1rem" }}/>
        <Box sx={{ cursor: "pointer", "&:hover": { textDecoration: "underline" }}} onClick={() => setFollowerDialogOpen("following")}>
          <Typography sx={{ fontWeight: "lighter" }}>Following</Typography>
          <Typography>{getFollowingCount()}</Typography>
        </Box>
        {
          hideBookClubs ? null : (
            <>
              <Divider orientation="vertical" sx={{ margin: "0 1rem" }}/>
              <Box sx={{ cursor: "pointer", "&:hover": { textDecoration: "underline" }}} onClick={() => setFollowerDialogOpen("bookclub")}>
                <Typography sx={{ fontWeight: "lighter" }}>Bookclubs</Typography>
                <Typography sx={{ fontWeight: "regular" }}>{getBookclubs()}</Typography>
              </Box>
            </>
          )
        }
      </Box>
      <FollowerDialog
        openTab={followerDialogOpen}
        handleClose={setFollowerDialogOpen}
        profileUid={profileUid}
        following={following}
        followers={followers}
        bookClub={bookClub}
      />
    </>
  )
}

export default FollowerCounts;