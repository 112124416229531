import * as React from "react";
import Lottie from "lottie-react";

import { useNavigate } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';

import NotificationsIcon from '@mui/icons-material/Notifications';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';

import LoadingIndicator from "../LoadingIndicator";
import NotifItem from "./NotifItem";

import { AuthContext } from "../../contexts/authProvider";

import { postReadNotifications } from "../../api/resources/utilities";

import empty from "../FullPageNotice_NoDiscussions/empty.json";

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha("rgba(0, 0, 0, 0.54)", 0.35),
  '&:hover': {
    backgroundColor: alpha("rgba(0, 0, 0, 0.54)", 0.45),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function Notifications(props: Props) {
  const { notifications, activeUid, getNotificationsRequest } = props;

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    postReadNotifications(activeUid);
    getNotificationsRequest()
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  const { user, handleSetUserDetails } = React.useContext(AuthContext);
  const uid = user.firebaseData.auth.currentUser.uid;

  const getUnreadCount = () => {
    if (
      notifications &&
      Object.prototype.hasOwnProperty.call(notifications, "allNotifs")
    ) {
      return notifications["allNotifs"].reduce((accumulator, obj) => {
        if (obj.unread) {
          return accumulator + 1;
        }

        return accumulator;
      }, 0);
    }

    return 0;
  }


  const getNotifPopoverContent = () => {
    if (!notifications) {
      return (
        <>
          <Box sx={{ maxWidth: "17rem", alignSelf: "center" }}>
            <LoadingIndicator />
          </Box>
          <Typography sx={{ fontSize: "1.4rem", fontStyle: "italic", fontFamily: "Lora, serif" }}>Retrieving notifications</Typography>
        </>
      )
    }

    if (notifications.allNotifs.length === 0) {
      return (
        <>
          <Box sx={{ maxWidth: "17rem", alignSelf: "center" }}>
            <Lottie animationData={empty} loop={true} />
          </Box>
          <Typography sx={{ fontSize: "1.4rem", fontStyle: "italic", fontFamily: "Lora, serif" }}>No notifications</Typography>
        </>
      )
    }

    const notifReversedCopy = structuredClone(notifications.allNotifs).reverse();

    return notifReversedCopy.map((item) => {
      return (
        <NotifItem
          key={item.actor + item.highlights}
          action={item.action}
          actorUid={item.actor}
          highlightMetadata={item.highlightMetadata}
          unread={item.unread}
          userMetadata={notifications.userMetadata}
          handlePopoverClose={handleClose}
          comments={item.comments}
        />
      )
    });
  }

  return (
    <>
      <IconButton {...props} onClick={handlePopoverOpen}>
        <Badge badgeContent={getUnreadCount()} color="primary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        disableAutoFocus
        disableEnforceFocus
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: { width: '100%', maxWidth: "420px", height: "369px", borderRadius: "20px", marginTop: "0.5rem" },
        }}
      >
        <Grid container sx={{ maxWidth: "880px", maxHeight: "500px", borderRadius: "20px", height: "100%" }}>
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", textAlign: "center", padding: "1rem", justifyContent: "center" }}>
            {getNotifPopoverContent()}
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}
