export const getBuyUrl = (asin, bookId) => {
  if (process.env.REACT_APP_AMZASSOCIATEID) {
    if (asin) {
      return `https://www.amazon.com/dp/${asin}/ref=nosim?tag=${process.env.REACT_APP_AMZASSOCIATEID}`;
    } else {
      return `https://www.amazon.com/s?k=${encodeURIComponent(bookId)}&tag=${process.env.REACT_APP_AMZASSOCIATEID}`;
    }
  } else {
    if (asin) {
      return `https://www.amazon.com/dp/${asin}`;
    } else {
      return `https://www.amazon.com/s?k=${encodeURIComponent(bookId)}`;
    }
  }
};