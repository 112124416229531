import React, { useState } from "react";

export const PublicLibraryContext = React.createContext({
  publicLibrary: {},
  setPublicLibrary: () => {},
});

function PublicLibraryProvider(props) {
  const [profileUid, setProfileUid] = useState(null)
  const [userdata, setUserdata] = useState(null)
  const [highlights, setHighlights] = useState(null)
  const [highlightsCount, setHighlightsCount] = useState(null)
  
  const handleSetUserdata = (userdata) => {
    let verifiedUserdata = [];
    
    if (userdata) {
      verifiedUserdata = userdata;
    }

    setUserdata(verifiedUserdata);
  };
  
  const handleSetHighlights = (highlights) => {
    let verifiedHighlights = {};

    if (highlights) {
      verifiedHighlights = highlights;
    }

    setHighlights(verifiedHighlights);
  };

  const handleSetHighlightsCount = (highlightsCount) => {
    let verifiedHighlightsCount = {};

    if (highlightsCount) {
      verifiedHighlightsCount = highlightsCount;
    }

    setHighlightsCount(verifiedHighlightsCount);
  };

  const handleSetProfileUid = (uid) => {
    let verifiedUid = "";

    if (uid) {
      verifiedUid = uid;
    }

    setProfileUid(verifiedUid);
  }

  const handleResetPublicLibrary = () => {
    setUserdata(null);
    setHighlights(null);
    setHighlightsCount(null);
    setProfileUid(null);
  };

  const value = { profileUid, userdata, highlights, highlightsCount, handleSetUserdata, handleSetHighlights, handleSetHighlightsCount, handleResetPublicLibrary, handleSetProfileUid };

  return <PublicLibraryContext.Provider value={value} {...props} />;
}

export { PublicLibraryProvider };
