import React, { useState } from "react";

export const AuthContext = React.createContext({
  user: {},
  setUser: () => {},
});

function AuthProvider(props) {
  const [user, setUser] = useState({ firebaseData: {}, isLoggedIn: false, userDetails: null });

  const handleSetUser = (value) => {
    // Check if logged in
    let loggedIn = false;

    if (value && value.auth.currentUser) {
      loggedIn = true;
    }

    setUser({ firebaseData: value, isLoggedIn: loggedIn, userDetails: user.userDetails });
  };

  const handleSetUserDetails = (value) => {
    // Check if logged in
    let validatedUserDetails = {};

    if (value) {
      validatedUserDetails = value;
    }

    setUser({ firebaseData: user.firebaseData, isLoggedIn: user.isLoggedIn, userDetails: validatedUserDetails });
  };

  const handleResetUser = () => {
    setUser({ firebaseData: user.firebaseData, isLoggedIn: false, userDetails: null });
  }

  const value = { user, handleSetUser, handleSetUserDetails, handleResetUser };

  return <AuthContext.Provider value={value} {...props} />;
}

export { AuthProvider };
