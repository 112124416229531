import * as React from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';

import LoadingIndicator from '../../components/LoadingIndicator';

import { getTopBooks } from '../../api/resources/utilities';

import { putJoinBookClub, putLeaveBookClub } from '../../api/resources/bookClub';

function JoinBookClubLoadingButton(props) {
  const { activeUid, bookId, isInBookClub, getTopBooksRequest } = props;

  const [btnLoading, setBtnLoading] = React.useState(false);

  const handleJoinBookClub = async (activeUid, bookId) => {
    setBtnLoading(true);

    const res = isInBookClub ?  await putLeaveBookClub(activeUid, bookId) : await putJoinBookClub(activeUid, bookId);
    
    await getTopBooksRequest();
    setBtnLoading(false);
  }

  return (
    <LoadingButton
      color={isInBookClub ? "error" : "primary"}
      loading={btnLoading}
      loadingPosition="start"
      startIcon={isInBookClub ? <MeetingRoomIcon /> : <Diversity1Icon />}
      variant="outlined"
      onClick={() => handleJoinBookClub(activeUid, bookId)}
    >
      {isInBookClub ? "Leave Book Club" : "Join Book Club"}
    </LoadingButton>
  )
}

export default function BookClubFollowSuggestions(props) {
  const { activeUid } = props;
  const [topBookClubs, setTopBookClubs] = React.useState(null);

  const getTopBooksRequest = async () => {
    const res = await getTopBooks();

    if (Object.prototype.hasOwnProperty.call(res, "topBooksMetadata")) {
      setTopBookClubs(res["topBooksMetadata"]);
    } else {
      setTopBookClubs([]);
    }
  };
  
  React.useEffect(() => {
    try {
      getTopBooksRequest();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getBookClubRecsContent = () => {
    if (!topBookClubs) {
      return (
        <Box sx={{ maxWidth: "20em", margin: "0 auto" }}>
          <LoadingIndicator />
        </Box>
      );
    }
  
    if (topBookClubs.length === 0) {
      return "NO RECOMMENDATIONS";
    } else {
      return topBookClubs.map((item) => (
        <ListItem
          key={item.uid}
          secondaryAction={
            <JoinBookClubLoadingButton
              activeUid={activeUid}
              bookId={item.title}
              isInBookClub={item.bookclubmembers.find(item => activeUid === item)}
              getTopBooksRequest={getTopBooksRequest}
            />
          }
        >
          <ListItemAvatar sx={{ paddingRight: "1rem" }}>
            <Avatar variant="rounded" alt={item.title} src={item.image} sx={{ width: 56, height: 80 }} />
          </ListItemAvatar>
          <ListItemText primary={item.title} secondary={item.author} />
        </ListItem>
      ))
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h3" sx={{ textAlign: "center" }}>Join Book Clubs</Typography>
        <Typography variant="body2" sx={{ textAlign: "center", fontStyle:"italic" }}>Book Clubs are unique spaces where you can find activities and discussions pertaining to a specific book.</Typography>
      </Grid>
      <Grid item xs={12}>
        {getBookClubRecsContent()}
      </Grid>
    </Grid>
  );
}