import 'react-toastify/dist/ReactToastify.css';

import * as React from "react";
import { ToastContainer } from 'react-toastify';
import { Outlet } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import Toolbar from "@mui/material/Toolbar";
import CottageIcon from "@mui/icons-material/Cottage";
import Stack from "@mui/material/Stack";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import StarRoundedIcon from '@mui/icons-material/StarRounded';

import { AuthContext } from "../../contexts/authProvider";

import SearchBar from "../../components/SearchBar";
import AppDrawer from "../../components/AppDrawer";
import Notifications from "../../components/Notifications";

import { getNotifications } from '../../api/resources/utilities';

const drawerWidth = 240;

const headerMenuItems = [
  { icon: <CottageIcon />, tooltip: "", route: "/feed" },
  { icon: <AutoStoriesIcon />, tooltip: "", route: "/library" },
  { icon: <StarRoundedIcon />, tooltip: "", route: "/gallery" },
];

export default function Main(props: Props) {
  const navigate = useNavigate();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [mobileSearchOpen, setMobileSearchOpen] = React.useState(false);
  const [notifications, setNotifications] = React.useState(null);

  const { user, handleSetUserDetails } = React.useContext(AuthContext);
  const uid = user.firebaseData.auth.currentUser.uid;

  const getNotificationsRequest = async () => {
    const res = await getNotifications(uid);

    setNotifications(res);
  }

  React.useEffect(() => {
    getNotificationsRequest();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          color="blippsWhite"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar sx={{ display: { xs: 'none', md: 'inherit' } }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" }, color: "gray" }}
            >
              <MenuIcon />
            </IconButton>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              sx={{ width: "100%", position: "absolute" }}
            >
              {headerMenuItems.map((item) => (
                <IconButton onClick={() => navigate(item.route)} key={item.route}>
                  {item.icon}
                </IconButton>
              ))}
            </Stack>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
              <SearchBar />
              <Notifications sx={{ marginLeft: "1rem" }} notifications={notifications} activeUid={uid} getNotificationsRequest={getNotificationsRequest} />
            </Box>
          </Toolbar>

          <Toolbar sx={{ display: { md: 'none' }, justifyContent: { xs: "space-between", sm: "flex-end"} }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" }, color: "gray" }}
            >
              <MenuIcon />
            </IconButton>
            {
              mobileSearchOpen ? (
                <Box sx={{ width: "100%", justifyContent: "end", display: "flex" }}>
                  <SearchBar autofocus="autofocus" />
                </Box>
              ) : (
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  sx={{ width: "100%" }}
                >
                  {headerMenuItems.map((item) => (
                    <IconButton onClick={() => navigate(item.route)} key={item.route}>
                      {item.icon}
                    </IconButton>
                  ))}
                </Stack>
              )
            }
            <IconButton onClick={() => setMobileSearchOpen(!mobileSearchOpen)}>
              {
                mobileSearchOpen ? (
                  <CancelIcon />
                ) : (
                  <SearchIcon /> 
                )
              }
            </IconButton>
            <Notifications notifications={notifications} activeUid={uid} getNotificationsRequest={getNotificationsRequest} />
          </Toolbar>
        </AppBar>
        <AppDrawer
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
      <ToastContainer />
    </>
  );
}
