import * as React from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import { styled } from "@mui/material/styles";

import { getBuyUrl } from "../../../processes/utilities";

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "black",
  backgroundColor: "rgb(255, 216, 20)",
  "&:hover": {
    backgroundColor: "rgb(247, 202, 0)",
  },
}));

export default function BuyBookButton(props) {
  const { bookId, asin, buttonText } = props;

  return (
    <ColorButton
      {...props}
      variant="contained"
      startIcon={<ShoppingCartIcon />}
      onClick={() => window.open(getBuyUrl(asin, bookId), "_blank", "noopener")}
    >
      {buttonText ? buttonText : "Buy Book"}
    </ColorButton>
  );
}
