import * as React from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import LoadingButton from '@mui/lab/LoadingButton';

export default function LoginForm() {
  const navigate = useNavigate();

  const [data, setData] = React.useState({ email: "", password: "" });
  const [error, setError] = React.useState("");
  const [formLoading, setFormLoading] = React.useState(false);

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setFormLoading(true);

    const auth = getAuth();
    signInWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
        navigate("/feed");
      })
      .catch((error) => {
        if (error.message.includes("auth/")) {
          setError("Invalid credentials.");
        } else {
          setError(error.message);
        }
        setFormLoading(false);
      });
  };

  return (
    <>
      <Grid item xs={12}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ display: "flex", flexDirection: "column", paddingTop: "2rem" }}
        >
          <TextField
            variant="outlined"
            type="email"
            placeholder="Email"
            name="email"
            onChange={handleChange}
            value={data.email}
            required
            sx={{ paddingBottom: "1rem" }}
          />
          <TextField
            variant="outlined"
            type="password"
            placeholder="Password"
            name="password"
            onChange={handleChange}
            value={data.password}
            required
          />
          {error && <FormHelperText error>{error}</FormHelperText>}
          <Stack
            spacing={1}
            direction="row"
            sx={{ paddingTop: "2rem", margin: "0 auto" }}
          >
            <LoadingButton
              loading={formLoading}
              variant="contained"
              type="submit"
            >
              Login
            </LoadingButton>
            <Button
              variant="outlined"
              onClick={() => navigate("/forgot-password")}
            >
              Forgot Password?
            </Button>
          </Stack>
        </Box>
      </Grid>
    </>
  );
}
