import { axiosClient } from "../apiClient";


export async function getUserdata(uid) {
  try {
    let res = await axiosClient.get("/getUserdata", { params: { uid: uid } });

    return res.data;
  } catch (error) {
    return [];
  }
}


export async function getPublicUserdata(uid, publicUid) {
  try {
    let res = await axiosClient.get(`/getUserdata/${publicUid}`, { params: { uid: uid } });

    return res.data;
  } catch (error) {
    return [];
  }
}


export async function postProfilePicture(uid, file) {
  try {
    const formData = new FormData();

    formData.append("uid", uid);
    formData.append("file", file);

    let res = await axiosClient.post("/uploadpicture", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return true;
  } catch (error) {
    return [];
  }
}


export async function postUserRegistration(data) {
  try {
    const res = await axiosClient.post("/api/users", data);

    return res;
  } catch (error) {
    return [];
  }
}


export async function getUserFollowing(uid) {
  try {
    const res = await axiosClient.get("/getFollowingProfile", { params: { uid: uid } });

    return res.data;
  } catch (error) {
    return [];
  }
}


export async function getUserFollowers(uid) {
  try {
    const res = await axiosClient.get("/getFollowerProfile", { params: { uid: uid } });

    return res.data;
  } catch (error) {
    return [];
  }
}
