import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import AddCommentIcon from '@mui/icons-material/AddComment';

import { postComment, postChildComment } from "../../api/resources/bookClub";
import { SignalCellularNull } from "@mui/icons-material";

export default function CommentComposer(props) {
  const { boxSx, userImageComponent, parentCommentId, bookId, activeUid, getCommentsRequest, collapseChildTextBox } = props;

  const [textValue, setTextValue] = React.useState('');

  const handleSubmit = async () => {
    if (textValue.length === 0) {
      return false;
    }

    //const { cleanQ } = q.replace(/[&/\#,+()$~%.'":*?<>{}] /g, '')
    const testStr = textValue.replace(/ /g,'');
    if (testStr.length === 0) {
      return false;
    }

    if (parentCommentId) {
      if (await postChildComment(activeUid, textValue, bookId, parentCommentId)) {
        getCommentsRequest();
        collapseChildTextBox();
        setTextValue('');
      }
    } else {
      if (await postComment(activeUid, textValue, bookId)) {
        getCommentsRequest();
        setTextValue('');
      }
    }

    return true;
  }

  return (
    <Box sx={{ ...boxSx, display: "flex", columnGap: "1rem", alignItems: "center", width: "100%" }}>
      {userImageComponent}
      <TextField
        {...props}
        fullWidth
        label="Say something!"
        multiline
        maxRows={4}
        value={textValue}
        onChange={(e) => setTextValue(e.target.value)}
        variant="outlined"
        focused={parentCommentId ? true : false}
      />
      <Button color="primary" variant="contained" onClick={handleSubmit}>
        <AddCommentIcon />
      </Button>
    </Box>

  );
}
