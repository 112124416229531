import * as React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CardMedia from '@mui/material/CardMedia';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import noCover from "../../assets/img/nocover.png";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import JoinBookClubButton from "../../components/Buttons/JoinBookClub";
import BuyBookButton from "../../components/Buttons/BuyBook";


export default function AnnotationCard(props) {
  const { title, image, author, highlightsCount, asin } = props;

  let navigate = useNavigate();

  return (
    <Box sx={{ width: "100%" }}>
      <Card
        raised
        variant="outlined"
        sx={{
          borderRadius: "20px",
          boxShadow: 3,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <CardMedia
          component="div"
          sx={{ objectFit: "contain", margin: "0.9rem auto" }}
        >
          <Box component="img" src={image ? image : noCover} sx={{ borderRadius: "5px", boxShadow: 3, height: 250 }} />
        </CardMedia>
        <CardContent sx={{ width: "100%" }}>
          <Grid spacing={1} container>
            {/* <Grid
              item
              xs={12}
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                sx={{ width: "100%", borderRadius: "10px", maxWidth: "169px" }}
                src={image}
              />
            </Grid> */}
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    mb: 1.5,
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "1.05rem",
                    color: "gray",
                  }}
                  variant="body2"
                >
                  {title}
                </Typography>
                <Typography
                  sx={{
                    mb: 1.5,
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "darkgray",
                  }}
                  variant="overline"
                >
                  {author}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: "center" }}>
          <Grid container spacing={1} sx={{ padding: "0 0.5rem" }}>
            <Grid item xs={12} lg={6}>
              <BuyBookButton fullWidth bookId={title} asin={asin} sx={{ height: "100%" }} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<ImportContactsIcon />}
                onClick={() => navigate(`item/${title}`)}
                sx={{ height: "100%" }}
              >
                {`${highlightsCount} Highlights`}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Box>
  );
}
