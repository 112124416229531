import * as React from 'react';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import UserFollowSuggestions from './UserFollowSuggestions';
import BookClubFollowSuggestions from './BookClubFollowSuggestions';
import FullPageOnboardNotice from '../../components/FullPageNotice_Onboard';

const steps = [
  {
    title: "Discover highlights",
    desc: "Follow some Blipps users to discover new highlights!",
  },
  {
    title: "Join the community",
    desc: "Join a Book Club to engage with other readers interested in the same book.",
  },
  {
    title: "Showcase your highlights",
    desc: "Upload highlights from your eReader for easy reference and sharing.",
  },
];

export default function NewUserStepper(props) {
  const { activeUid, hasBookClub, hasFollowing, hideOnboarding } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  // if (hasBookClub) {
  //   setActiveStep(1);
  // }

  const isStepOptional = (step: number) => {
    return step === 1 || step === 0;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === steps.length - 1) {
      hideOnboarding();
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getStepperView = (activeStep, activeUid) => {
    switch (activeStep) {
      case 0:
        return <UserFollowSuggestions activeUid={activeUid} />;
      
      case 1:
        return <BookClubFollowSuggestions activeUid={activeUid} />;
      
      case 2:
        return <FullPageOnboardNotice nestedOnboarding uid={activeUid}  />;

      default:
        return "All steps completed - we hope you&apos;ll have a great time discovering Blipps!";
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => {
          const stepProps: { completed?: boolean } = {};

          const labelProps: {
            optional?: React.ReactNode;
          } = {};

          labelProps.optional = (
            <Typography variant="caption">{step.desc}</Typography>
          );

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }

          return (
            <Step key={step.title} {...stepProps}>
              <StepLabel {...labelProps}>{step.title}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <React.Fragment>
          {getStepperView(activeStep, activeUid)}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <Grid item sx={{ paddingTop: "2rem" }}>
          {getStepperView(activeStep, activeUid)}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              size="large"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button size="large" color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}
            <Button size="large" variant="contained" onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </Grid>
      )}
    </Box>
  );
}