import * as React from "react";
import CountUp from "react-countup";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

import { AuthContext } from "../../contexts/authProvider";

import { getUserdata } from "../../api/resources/user";
import { getTitles } from "../../api/resources/utilities";
import { getBookClub } from "../../api/resources/bookClub";

import FollowerCounts from "../FollowerCounts";

export default function SidebarProfile() {
  const { user, handleSetUserDetails } = React.useContext(AuthContext);
  const uid = user.firebaseData.auth.currentUser.uid;

  const [bookClub, setBookClub] = React.useState(null);

  React.useEffect(() => {
    try {
      const getHighlightsRequest = async () => {
        if (user.userDetails === null) {
          const res = await getUserdata(uid);
          const bookClubRes = await getBookClub(uid);

          handleSetUserDetails(res);
          setBookClub(bookClubRes);

          getTitles();
        }
      };

      getHighlightsRequest();
    } catch (error) {
      console.log(error);
    }
  }, [uid]);

  const getName = () => {
    if (user.userDetails) {
      if (Object.prototype.hasOwnProperty.call(user.userDetails, "userinfo")) {
        if (user.userDetails.userinfo.length > 0) {
          let firstName = user.userDetails["userinfo"][0]["firstName"];
          let lastName = user.userDetails["userinfo"][0]["lastName"];

          if (firstName) {
            firstName = firstName.trim();
          } else {
            firstName = "";
          }

          if (lastName) {
            lastName = lastName.trim();
          } else {
            lastName = "";
          }

          return firstName + " " + lastName;
        }
      }
    }
    return "The Nameless";
  };

  const getBooksCount = () => {
    if (user.userDetails && user.userDetails.userBooksCount) {
      return user.userDetails.userBooksCount;
    }

    return 0;
  };

  const getHighlightsCount = () => {
    if (user.userDetails && user.userDetails.userhighlightsCount) {
      return user.userDetails.userhighlightsCount;
    }

    return 0;
  };

  const getBookClubCount = () => {
    if (bookClub) {
      return bookClub.length;
    }

    return 0;
  };

  const getUserImage = () => {
    function stringToColor(string: string) {
      let hash = 0;
      let i;

      /* eslint-disable no-bitwise */
      for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }

      let color = "#";

      for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
      }
      /* eslint-enable no-bitwise */

      return color;
    }

    const stringAvatar = (name: string) => {
      return {
        sx: {
          bgcolor: stringToColor(name),
          width: "7rem",
          height: "7rem",
          margin: "0 auto",
          fontSize: "2rem",
        },
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
      };
    };

    if (user.userDetails) {
      if (Object.prototype.hasOwnProperty.call(user.userDetails, "userinfo")) {
        if (
          user.userDetails["userinfo"] &&
          user.userDetails["userinfo"].length > 0 &&
          user.userDetails["userinfo"][0]["img"]) {
          if (
            user.userDetails["userinfo"][0]["img"]["contentType"].includes(
              "image/",
            )
          ) {
            const imageDetails = user.userDetails["userinfo"][0]["img"];

            return (
              <Box
                component="img"
                src={`data:${imageDetails.contentType};base64, ${imageDetails.img}`}
                sx={{
                  borderRadius: "50%",
                  //border: "1px dashed grey",
                  width: "7rem",
                  height: "7rem",
                }}
              />
            );
          }
        }
      }
    }

    return <Avatar {...stringAvatar(getName())} />;
  };

  return (
    <Box sx={{ textAlign: "center", margin: "0 auto" }}>
      {getUserImage()}
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: "1rem" }}>
        <FollowerCounts hideBookClubs noMargins profileUid={uid} />
      </Box>
      <Typography
        variant="body1"
        sx={{
          textAlign: "center",
          margin: "2rem 1rem 0.5rem 1rem",
          fontSize: "1.5rem",
          fontWeight: "bold",
        }}
      >
        {getName()}
      </Typography>
      <Card
        raised
        variant="outlined"
        sx={{
          borderRadius: "20px",
          boxShadow: 3,
          display: "flex",
          flexDirection: "column",
          padding: "0.5rem",
          lineHeignt: "2rem",
          textAlign: "center",
          width: "10rem",
          margin: "1rem auto",
        }}
      >
        <Typography variant="body1">Books Read</Typography>
        <Typography variant="h2">
          <CountUp end={getBooksCount()} />
        </Typography>
      </Card>

      <Card
        raised
        variant="outlined"
        sx={{
          borderRadius: "20px",
          boxShadow: 3,
          display: "flex",
          flexDirection: "column",
          padding: "0.5rem",
          lineHeignt: "2rem",
          textAlign: "center",
          width: "10rem",
          margin: "1rem auto",
        }}
      >
        <Typography variant="body1">Highlights</Typography>
        <Typography variant="h2">
          <CountUp end={getHighlightsCount()} />
        </Typography>
      </Card>
      
      <Card
        raised
        variant="outlined"
        sx={{
          borderRadius: "20px",
          boxShadow: 3,
          display: "flex",
          flexDirection: "column",
          padding: "0.5rem",
          lineHeignt: "2rem",
          textAlign: "center",
          width: "10rem",
          margin: "1rem auto",
        }}
      >
        <Typography variant="body1">Book Clubs</Typography>
        <Typography variant="h2">
          <CountUp end={getBookClubCount()} />
        </Typography>
      </Card>
    </Box>
  );
}
