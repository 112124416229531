import * as React from 'react';
import html2canvas from "html2canvas";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IosShareIcon from '@mui/icons-material/IosShare';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import TitleIcon from '@mui/icons-material/Title';
import ImageIcon from '@mui/icons-material/Image';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';

import FavouriteCard from '../FavouriteCard';
import FavouriteCardPlain from '../FavouriteCard_Plain';

export default function ShareDialog(props) {
  const componentRef = React.useRef();

  const { image, author, highlights, title, open, handleClose } = props;

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  const [randomNumber, setRandomNumber] = React.useState(getRandomInt(47));
  const [cardType, setCardType] = React.useState("picture");

  const handleShare = async (title, author, highlight, event) => {
    const element = componentRef.current;

    const canvas = await html2canvas(element, {
      allowTaint: true,
      backgroundColor: "none",
      logging: true,
      windowWidth: 1920,
      windowHeight: 1080,
      useCORS: true //to enable cross origin perms
    });

    canvas.toBlob(async (blob) => {
      const data = {
        files: [
          new File([blob], 'image.png', {
            type: blob.type,
          }),
        ],
        title: title,
        text: `${highlight}
        
${title} - ${author}`,

      }; // Text whitespace is in this manner such that it aligns properly when shared
  
      try {
        await navigator.share(data);
        // await new Promise((resolve, reject) => {
        //   navigator.share(data).then(resolve).catch(error => {
        //     // Differentiate between user 'AbortError' and internal errors.
        //     // E.g. Internal error: could not connect to Web Share interface.
        //     if (error.message.startsWith('Internal error:'))
        //       error.name = 'InternalError';
        
        //     reject(error);
        //   });
        
        //   /*
        //    * https://bugs.chromium.org/p/chromium/issues/detail?id=636274
        //    * If the share promise is not resolved or rejected when focus is returned to
        //    * the window, then reject it after a 100ms delay.
        //    */
        //   let cancel = () => setTimeout(() => {
        //     window.removeEventListener('focus', cancel);
        
        //     let error = new Error('Share cancelled');
        //     error.name = 'ShareTimeout';
        //     reject(error);
        //   }, 100);
        
        //   window.addEventListener('focus', cancel);
        // });
      } catch (err) {
        if (!err.toString().toLowerCase().includes("abort")) {
          await handleDownloadImage();
        }
      }
      event.preventDefault();
    })
}


  const handleCardTypeChange = () => {
    if (cardType === "picture") {
      setCardType("text");
    } else {
      setCardType("picture");
    }

    return true;
  }

  const handleDownloadImage = async () => {
    const element = componentRef.current;
    const canvas = await html2canvas(element, {
      allowTaint: true,
      backgroundColor: "none",
      logging: true,
      windowWidth: 1920,
      windowHeight: 1080,
      useCORS: true //to enable cross origin perms
    });

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      console.log("");
      link.download = "HighlightCard";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  const handleCardShare = async (event) => {
    await handleShare(title, author, highlights, event);
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose("closed")}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          sx: {
            minHeight: "80%"
          }
        }}  
      >
        <DialogTitle>
          <Typography variant="h5">Share Highlight</Typography>
        </DialogTitle>
        <DialogContent sx={{ flexDirection: "column", display: "flex", justifyContent: "center" }} >
          <Box sx={{ display: "flex", justifyContent: "center", height: "100%" }}>
            {
              cardType === "picture" ? (
                <Box sx={{ width: "100%", maxWidth: "420px" }}>
                  <FavouriteCard ref={componentRef} randomNumber={randomNumber} highlight={highlights} author={author} title={title} /> 
                </Box>
              ) : (
                <Box sx={{ display: "flex" }}>
                  <FavouriteCardPlain ref={componentRef} image={image} highlight={highlights} author={author} title={title} />
                </Box>
              )
            }
          </Box>
          {
            cardType === "picture" ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <IconButton onClick={() => setRandomNumber(getRandomInt(46))} size="large">
                  <ShuffleIcon />
                </IconButton>
              </Box>
            ) : null
          }
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", padding: "0 1rem" }}>
          <Box sx={{ display: "flex" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <TitleIcon />
              <Switch defaultChecked checked={ cardType === "picture" } onChange={handleCardTypeChange} color="default" />
              <ImageIcon />
            </Stack>
          </Box>
          <Box>
            <Button size="large" color="warning" onClick={() => handleClose()} sx={{ marginRight: "0.5em" }}>Cancel</Button>
              
            <Button
              size="large"
              variant="contained"
              onClick={() => handleCardShare()}
              startIcon={<IosShareIcon />}
              sx={{ margin: "1rem 0" }}
            >
              Share
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}