import React from "react";
import { useNavigate } from "react-router-dom";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

export default function AppListItem(props: Props) {
  const { key, to, icon, name, func } = props;

  const navigate = useNavigate();

  const handleItemClick = () => {
    if (to) {
      navigate(to);
    } else if (func) {
      func();
    }

    return null;
  };

  return (
    <ListItem key={key} disablePadding>
      <ListItemButton onClick={() => handleItemClick()}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={name} />
      </ListItemButton>
    </ListItem>
  );
}
