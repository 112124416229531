import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import LoadingIndicator from "../../../components/LoadingIndicator";
import { AuthContext } from "../../../contexts/authProvider";
import { PublicLibraryContext } from "../../../contexts/publicLibraryProvider";
import { LibraryContext } from "../../../contexts/libraryProvider";

import noCover from "../../../assets/img/nocover.png";

import HighlightsCardPlain from "../../../components/HighlightsCardPlain";
import FullPageJoinBookClubNotice from "../../../components/FullPageNotice_JoinBookClub";
import FullPageNoHighlights from "../../../components/FullPageNotice_NoHighlights";
import BuyBookButton from "../../../components/Buttons/BuyBook";
import AddHighlightButton from "../../../components/Buttons/AddHighlight";
import NewHighlightsDialog from "../../../components/NewHighlightDialog";
import BookDiscussions from "../../../components/BookDiscussions";
import LoginDialog from "../../../components/LoginDialog";

import { getPublicUserdata, getUserdata } from "../../../api/resources/user";
import {
  getHighlights,
  getPublicHighlights,
} from "../../../api/resources/library";
import {
  getbookHighlights,
  putJoinBookClub,
  putLeaveBookClub,
} from "../../../api/resources/bookClub";

import { getUid } from "../../../processes/user";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function LibraryBook(props) {
  const { otherUsers } = props;

  const [followBtnLoading, setFollowBtnLoading] = React.useState(false);
  const [panelValue, setPanelValue] = React.useState(0);
  const [bookClubData, setBookClubData] = React.useState(null);
  const [prevBookClub, setPrevBookClub] = React.useState(null);
  const [addHighlightsOpen, setAddHighlightsOpen] = React.useState(false);
  const [loginDialogOpen, setLoginDialogOpen] = React.useState(false);

  const { user, handleSetUserDetails } = React.useContext(AuthContext);
  const { library, handleSetLibrary } = React.useContext(LibraryContext);
  const {
    profileUid,
    userdata,
    highlights,
    highlightsCount,
    handleSetUserdata,
    handleSetHighlights,
    handleSetHighlightsCount,
    handleResetPublicLibrary,
    handleSetProfileUid,
  } = React.useContext(PublicLibraryContext);

  const uid = getUid(user.firebaseData);

  const { bookId, userId } = useParams();

  // Other users
  React.useEffect(() => {
    try {
      const getHighlightsRequest = async () => {
        if (otherUsers) {
          const res = await getPublicHighlights(uid, userId);

          handleSetHighlights(res);
        }
      };

      getHighlightsRequest();
    } catch (error) {
      console.log(error);
    }
  }, [bookId, userId]);

  React.useEffect(() => {
    try {
      const getUserdataRequest = async () => {
        if (otherUsers) {
          const res = await getPublicUserdata(uid, userId);

          handleSetUserdata(res);
        }
      };

      getUserdataRequest();
    } catch (error) {
      console.log(error);
    }
  }, [profileUid]);

  // Logged in user
  React.useEffect(() => {
    try {
      const getHighlightsRequest = async () => {
        if (!otherUsers) {
          const res = await getHighlights(uid);

          const parsedMetadata = {};

          res["bookMetadata"].forEach((item) => {
            if (!item) {
              return;
            }

            parsedMetadata[item.title] = {
              image: item.image,
            };
          });

          handleSetLibrary(
            res.cards,
            parsedMetadata,
            res.likersMetadata,
            res.FavHighlights,
          );
        }
      };

      const getBookClub = async () => {
        if (prevBookClub === null) {
          setPrevBookClub(bookId);
        } else if (prevBookClub === bookId) {
        } else {
          setPrevBookClub(bookId);
          setBookClubData(null);
        }

        const res = await getbookHighlights(uid, bookId);

        setBookClubData(res);
      };

      getHighlightsRequest();
      getBookClub();
    } catch (error) {
      console.log(error);
    }
  }, [uid, bookId, user]);

  const navigate = useNavigate();

  const handleAddHighlightsOpen = () => {
    if (uid) {
      setAddHighlightsOpen(true);
    } else {
      setLoginDialogOpen(true);
    }
  };

  const getImage = () => {
    if (
      bookClubData &&
      bookClubData.bookMetadata &&
      bookClubData.bookMetadata.length > 0 &&
      bookClubData.bookMetadata[0]["image"]
    ) {
      return bookClubData.bookMetadata[0]["image"];
    }

    return noCover;
  };

  const getBookUid = () => {
    if (
      bookClubData &&
      bookClubData.bookMetadata &&
      bookClubData.bookMetadata.length > 0 &&
      bookClubData.bookMetadata[0]["_id"]
    ) {
      return bookClubData.bookMetadata[0]["_id"];
    }

    return null;
  };

  const bookUid = getBookUid();

  const getHighlightsForBook = () => {
    if (otherUsers) {
      if (highlights && highlights.cards) {
        const arrHighlights = highlights.cards.filter(
          (item) => item.title === bookId,
        );

        if (arrHighlights.length === 0) {
          return <div>No Highlights</div>;
        }

        return (
          <>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingBottom: "1rem",
                }}
              >
                <AddHighlightButton
                  onClick={() => handleAddHighlightsOpen(true)}
                />
              </Box>
            </Grid>
            {arrHighlights.map((item) => (
              <Grid item sx={{ width: "100%" }} key={item.highlights}>
                <HighlightsCardPlain
                  otherUsers
                  highlightId={item._id}
                  details={item.details}
                  highlights={item.highlights}
                  activeUid={uid}
                  profileUid={userId}
                  highlightCreatorUserId={item.uid}
                  likers={item.likes}
                  likerProfiles={highlights.likersMetadata}
                  creatorProfiles={highlights.userMetadata}
                  isHighlightCreator={item.uid === uid}
                  author={item.author}
                  title={item.title}
                />
              </Grid>
            ))}
          </>
        );
      }
    } else {
      if (!library || !library.highlights) {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Box sx={{ maxWidth: "20em" }}>
              <LoadingIndicator />
            </Box>
          </Box>
        );
      }

      const arrHighlights = library.highlights.filter(
        (item) => item.title === bookId,
      );

      if (arrHighlights.length === 0) {
        return <FullPageNoHighlights bookId={bookId} asin={getAsinForBook()} />;
      }

      return (
        <>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                paddingBottom: "1rem",
              }}
            >
              <AddHighlightButton
                onClick={() => handleAddHighlightsOpen(true)}
              />
            </Box>
          </Grid>
          {arrHighlights.map((item) => (
            <Grid item sx={{ width: "100%" }} key={item.highlights}>
              <HighlightsCardPlain
                highlightId={item._id}
                details={item.details}
                highlights={item.highlights}
                activeUid={uid}
                highlightCreatorUserId={item.uid}
                likers={item.likes}
                likerProfiles={library.likersMetadata}
                // creatorProfiles={bookClubData.userMetadata}
                isHighlightCreator={true}
                author={item.author}
                title={item.title}
              />
            </Grid>
          ))}
        </>
      );
    }
  };

  const getAuthorForBook = () => {
    if (!library || !library.highlightCounts) {
      return null;
    }

    const book = library.highlightCounts.filter(
      (item) => item.title === bookId,
    );

    if (book && book.length > 0) {
      return book[0].author;
    }

    return null;
  };

  const handleJoinBookClub = async () => {
    setFollowBtnLoading(true);

    if (uid && bookId) {
      await putJoinBookClub(uid, bookId);
      const userdataRes = await getUserdata(uid);

      handleSetUserDetails(userdataRes);
    } else {
      setLoginDialogOpen(true);
    }
    setFollowBtnLoading(false);
  };

  const handleLeaveBookClub = async () => {
    setFollowBtnLoading(true);

    if (uid && bookId) {
      await putLeaveBookClub(uid, bookId);
      const userdataRes = await getUserdata(uid);

      handleSetUserDetails(userdataRes);
    }
    setFollowBtnLoading(false);
  };

  const bookClubJoinStatus = () => {
    let isFollowing = false;

    if (user && user.userDetails && user.userDetails.userinfo.length > 0) {
      if (
        Object.prototype.hasOwnProperty.call(
          user.userDetails.userinfo[0],
          "bookclub",
        )
      ) {
        isFollowing = user.userDetails.userinfo[0]["bookclub"].find(
          (elem) => elem === bookId,
        );
      }
    }

    return isFollowing;
  };

  const isFollowing = bookClubJoinStatus();

  const getFollowButton = () => {
    return (
      <LoadingButton
        variant="outlined"
        startIcon={isFollowing ? <MeetingRoomIcon /> : <Diversity1Icon />}
        color={isFollowing ? "error" : "primary"}
        onClick={() =>
          isFollowing ? handleLeaveBookClub() : handleJoinBookClub()
        }
        loading={followBtnLoading}
      >
        {isFollowing ? "Quit Book Club" : "Join Book Club"}
      </LoadingButton>
    );
  };

  const getHighlightsForBookClub = () => {
    if (!bookClubData || !bookClubData.cards) {
      return null;
    }

    if (bookClubData.cards.length === 0) {
      return <div>No Highlights</div>;
    }

    return bookClubData.cards.map((item) => (
      <Grid item xs={12} sx={{ width: "100%" }} key={item.highlights}>
        <HighlightsCardPlain
          highlightId={item._id}
          details={item.details}
          highlights={item.highlights}
          activeUid={uid}
          highlightCreatorUserId={item.uid}
          likers={item.likes}
          likerProfiles={bookClubData.likersMetadata}
          creatorProfiles={bookClubData.userMetadata}
          isBookClub
          isHighlightCreator={true}
          author={item.author}
          title={item.title}
        />
      </Grid>
    ));
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPanelValue(newValue);
  };

  const getBookClub = () => {
    if (!isFollowing) {
      return <FullPageJoinBookClubNotice />;
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12} sx={{ paddingBottom: "1rem" }}>
            <Typography variant="h5">Club Highlights</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                paddingBottom: "1rem",
              }}
            >
              <AddHighlightButton
                onClick={() => handleAddHighlightsOpen(true)}
              />
            </Box>
          </Grid>
          <Grid
            container
            spacing={1}
            xs={12}
            sx={{ overflowY: "scroll", maxHeight: "30rem" }}
          >
            {getHighlightsForBookClub()}
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ paddingTop: "1rem" }}>
          <Grid item xs={12} sx={{ paddingBottom: "1rem" }}>
            <Typography variant="h5">Club Discussion</Typography>
            <Divider />
          </Grid>
          <BookDiscussions bookId={bookUid} activeUid={uid} />
        </Grid>
      </Grid>
    );
  };

  const getTabFormalities = () => {
    if (otherUsers) {
      if (userdata && userdata.userinfo && userdata.userinfo.length > 0) {
        return `${userdata.userinfo[0]["firstName"].trim()}'s Highlights`;
      }
    }

    return "Your Highlights";
  };

  const getAsinForBook = () => {
    if (!library || !library.highlightCounts) {
      return null;
    }

    const book = library.highlightCounts.filter(
      (item) => item.title === bookId,
    );

    if (book && book.length > 0) {
      return book[0].asin;
    }

    return null;
  };

  const getBackButtonRoute = () => {
    if (otherUsers) {
      return `/library/${userId}`;
    }

    return "/library";
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            size="small"
            startIcon={<ChevronLeftIcon />}
            onClick={() => navigate(getBackButtonRoute())}
          >
            Back to Library
          </Button>
        </Grid>

        <Grid item xs={12} sx={{ display: "flex", gap: "1rem" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                md={3}
                lg={2}
                xl={1}
                sx={{ textAlign: "center" }}
              >
                <Box
                  component="img"
                  src={getImage()}
                  sx={{ maxWidth: "100%", borderRadius: "10px", boxShadow: 3 }}
                />
              </Grid>
              <Grid item xs={12} md={9} lg={10} xl={11}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    paddingLeft: "1rem",
                  }}
                >
                  <Typography variant="h3">{bookId}</Typography>
                  <Typography sx={{ fontStyle: "italic" }}>
                    {getAuthorForBook()}
                  </Typography>
                  <Box sx={{ paddingTop: "1rem" }}>
                    <BuyBookButton bookId={bookId} asin={getAsinForBook()} />
                    <LoadingButton
                      variant="outlined"
                      startIcon={
                        isFollowing ? <MeetingRoomIcon /> : <Diversity1Icon />
                      }
                      color={isFollowing ? "error" : "primary"}
                      onClick={() =>
                        isFollowing
                          ? handleLeaveBookClub()
                          : handleJoinBookClub()
                      }
                      loading={followBtnLoading}
                      sx={{ marginLeft: "0.5rem" }}
                    >
                      {isFollowing ? "Quit Book Club" : "Join Book Club"}
                    </LoadingButton>
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}></Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={panelValue} onChange={handleChange}>
              <Tab label={getTabFormalities()} {...a11yProps(0)} />
              <Tab label="Book Club" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={panelValue} index={0}>
            <Grid container spacing={1}>
              {getHighlightsForBook()}
            </Grid>
          </TabPanel>
          <TabPanel value={panelValue} index={1}>
            {getBookClub()}
          </TabPanel>
        </Grid>
      </Grid>
      <NewHighlightsDialog
        open={addHighlightsOpen}
        handleClose={() => setAddHighlightsOpen(false)}
        activeUid={uid}
        existingTitle={bookId}
        existingAuthor={getAuthorForBook()}
        otherUsers={otherUsers}
      />
      <LoginDialog
        isOpen={loginDialogOpen}
        handleClose={() => setLoginDialogOpen(false)}
      />
    </>
  );
}
