import * as React from 'react';
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuBookIcon from '@mui/icons-material/MenuBook';

import Grid from "@mui/material/Grid";

import LoadingIndicator from '../LoadingIndicator';
import UserImage from '../UserImage';

import empty from "../FullPageNotice_NothingHere/empty.json";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function EmptyFollowers(props) {
  const { followerOrFollowing } = props;

  const getText = (followerOrFollowing) => {
    switch (followerOrFollowing) {
      case "follower":
        return "No followers yet!";
      case "following":
        return "Not following anybody yet!";
      case "bookclub":
        return "Not in any Book Clubs yet!";
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Lottie animationData={empty} loop={true} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" sx={{ textAlign: "center", fontSize: "1.2rem", fontStyle: "italic", paddingTop: "3rem" }}>{getText(followerOrFollowing)}</Typography>
      </Grid>
    </Grid>
  )
}

export default function FollowersDialog(props) {
  const { openTab, profileUid, handleClose, followers, following, bookClub } = props;

  const navigate = useNavigate();

  const indexOfOpenTab = ["follower", "following", "bookclub"].indexOf(openTab);

  const [value, setValue] = React.useState(indexOfOpenTab);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getFollowers = () => {
    if (!followers) {
      return <LoadingIndicator />;
    }

    if (followers.length === 0) {
      return <EmptyFollowers followerOrFollowing="follower" />;
    }

    if (followers.filter((item) => item["userMetadata"][0] !== null).length === 0) {
      return <EmptyFollowers followerOrFollowing="following" />;
    } 

    return followers.map((item) => {
      const userItr = item.userMetadata[0];

      if (!userItr) {
        return null;
      }

      return (
        <ListItemButton
          onClick={() => {
            handleClose(false);
            navigate(`/library/${userItr.uid}`);
          }}
          key={userItr.uid}
        >
          <ListItemAvatar sx={{ paddingRight: "1rem" }}>
            <UserImage firstName={userItr.firstName} lastName={userItr.lastName} imageMetadata={userItr.img} isLarge />
          </ListItemAvatar>
          <ListItemText primary={userItr.firstName + " " + userItr.lastName} secondary="A Fellow Blipps User" />
        </ListItemButton>
      )
    });
  }

  const getFollowing = () => {
    if (!following) {
      return <LoadingIndicator />;
    }

    if (following.length === 0) {
      return <EmptyFollowers followerOrFollowing="following" />;
    }

    if (following.filter((item) => item["userMetadata"][0] !== null).length === 0) {
      return <EmptyFollowers followerOrFollowing="following" />;
    } 

    return (
      <List>
      {
        following.map((item) => {
          const userItr = item.userMetadata[0];

          if (!userItr) {
            return null;
          }
    
          return (
            <ListItemButton
              onClick={() => {
                handleClose(false);
                navigate(`/library/${userItr.uid}`);
              }}
              key={userItr.uid}
            >
              <ListItemAvatar sx={{ paddingRight: "1rem" }}>
                <UserImage firstName={userItr.firstName} lastName={userItr.lastName} imageMetadata={userItr.img} isLarge />
              </ListItemAvatar>
              <ListItemText primary={userItr.firstName + " " + userItr.lastName} secondary="A Fellow Blipps User" />
            </ListItemButton>
          )
        })
      }
      </List>
    )
  }

  const getBookImage = (item) => {
    if (item && item.image) {
      return (
        <ListItemAvatar>
          <Box component="img" src={item.image} sx={{ maxWidth: "2.7rem", borderRadius: "7px" }} />
        </ListItemAvatar>
      )
    } else {
      return (
        <ListItemIcon>
          <MenuBookIcon />
        </ListItemIcon>
      )
    }

    function stringToColor(string: string) {
      let hash = 0;
      let i;
    
      /* eslint-disable no-bitwise */
      for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }
    
      let color = '#';
    
      for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
      }
      /* eslint-enable no-bitwise */
    
      return color;
    }
    
    const stringAvatar = (name: string) => {
      return {
        sx: {
          bgcolor: stringToColor(name),
          width: "7rem",
          height: "7rem",
          margin: "0 auto",
          fontSize: "2rem",
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      };
    }

    // if (user.userDetails) {
    //   if (Object.prototype.hasOwnProperty.call(user.userDetails, "userinfo")) {
    //     if (user.userDetails["userinfo"][0]["img"]) {
    //       if (user.userDetails["userinfo"][0]["img"]["contentType"].includes("image/")) {
    //         const imageDetails = user.userDetails["userinfo"][0]["img"];

    //         return (
    //           <Box
    //             component="img"
    //             src={`data:${imageDetails.contentType};base64, ${imageDetails.img}`}
    //             sx={{
    //               borderRadius: "50%",
    //               //border: "1px dashed grey",
    //               width: "7rem",
    //               height: "7rem",
    //             }}
    //           />
    //         )
    //       }
    //     }
    //   }
    // }

    // return <Avatar {...stringAvatar(getName())} />;
  }

  const getBookClub = () => {
    if (!bookClub) {
      return <LoadingIndicator />;
    }

    if (bookClub.length === 0) {
      return <EmptyFollowers followerOrFollowing="bookclub" />;
    }

    if (bookClub.filter((item) => item["bookMetadata"][0] !== null).length === 0) {
      return <EmptyFollowers followerOrFollowing="following" />;
    } 

    return (
      <List>
      {
        bookClub.map((item) => {
          const bookItr = item.bookMetadata[0];

          if (!bookItr) {
            return null;
          }

          return (
            <ListItemButton
              onClick={() => {
                handleClose(false);
                navigate(`/library/item/${bookItr.title}`);
              }}
              key={bookItr._id}
            >
              <ListItemAvatar sx={{ paddingRight: "1rem" }}>
                {getBookImage(bookItr)}
              </ListItemAvatar>
              <ListItemText primary={bookItr.title} secondary={
                <>
                  <Typography>{bookItr.subtitle}</Typography>
                  <Typography sx={{ fontStyle: "italic" }}>{bookItr.author}</Typography>
                </>
              } />
              {/* <ListItemText primary={userItr.firstName + " " + userItr.lastName} secondary="A Fellow Blipps User" /> */}
            </ListItemButton>
          )
        })
      }
      </List>
    )
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="Followers" />
          <Tab label="Following" />
          <Tab label="Bookclubs" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {getFollowers()}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {getFollowing()}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {getBookClub()}
      </TabPanel>
    </Box>
  );
}