import * as React from "react";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Button from '@mui/material/Button';

import { AuthContext } from "../../contexts/authProvider";
import { Link, useNavigate } from "react-router-dom";
import FullPageUnderConstructionNotice from "../../components/FullPageNotice_UnderConstruction";

import { Grid, Box } from "@mui/material";
import { postProfilePicture } from "../../api/resources/user";

const Settings = () => {
  const { user, handleSetUserDetails } = React.useContext(AuthContext);
  const uid = user.firebaseData.auth.currentUser.uid;

  const [data, setData] = React.useState({ email: "", password: "" });
  const [error, setError] = React.useState("");
  const navigate = useNavigate();

  const handleChange = async (file) => {
    try {
      await postProfilePicture(uid, file)

      return true;
    } catch (error) {
      return [];
    }
  };

  return (
    <Box>
      <Grid container spacing={3} sx={{ justifyContent: "center" }}>
        <Grid item xs={12}>
          <Typography variant="h2">Settings</Typography>
          <Card
            raised
            variant="outlined"
            sx={{ borderRadius: "20px", boxShadow: 3, padding: "1rem" }}
          >
            <Box>
              <Typography variant="h4">User Profile</Typography>
            </Box>
            <Box sx={{ display: "grid", justifyContent: "center" }}>
              <Button variant="contained" component="label">
                Set Profile Picture
                <input hidden accept="image/*" type="file" onChange={(event) => handleChange(event.target.files[0])} />
              </Button>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            raised
            variant="outlined"
            sx={{ borderRadius: "20px", boxShadow: 3, padding: "1rem" }}
          >
            <Typography variant="h4">Site Preferences</Typography>
            <FullPageUnderConstructionNotice />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Settings;
