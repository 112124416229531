import * as React from "react";

import { Route, Routes, Navigate, Outlet } from "react-router-dom";

import { getAuth, onAuthStateChanged } from "firebase/auth";

import Import from "./containers/Import";
import Feed from "./containers/Feed";
import Library from "./containers/Library";
import AllBooks from "./containers/Library/AllBooks";
import Signup from "./containers/SignUp";
import Settings from "./containers/Settings";
import Gallery from "./containers/Gallery";
import Login from "./containers/Login";
import ForgotPassword from "./containers/ForgotPassword";
import LibraryBook from "./containers/Library/LibraryBook";
import LandingPage from "./containers/LandingPage";

import Main from "./layouts/Main";
import Minimal from "./layouts/Minimal";
import Landing from "./layouts/Landing";

import { AuthContext } from "./contexts/authProvider";

import LoadingIndicator from "./components/LoadingIndicator";

import Box from "@mui/material/Box";

const ProtectedRoute = ({ user, redirectPath = "/login" }) => {
  if (user.firebaseData && Object.keys(user.firebaseData).length === 0) {
    return (
      <Box sx={{ maxWidth: "30rem", margin: "0 auto" }}>
        <LoadingIndicator />
      </Box>
    );
  }

  if (!user.isLoggedIn) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

const DynamicAuthLayouts = ({ user }) => {
  if (!user.isLoggedIn) {
    return <Landing />;
  }

  return <Main />;
}

const AppRoutes = () => {
  const { user, handleSetUser } = React.useContext(AuthContext);

  const auth = getAuth();

  React.useEffect(() => {
    onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        handleSetUser(firebaseUser);
      } else {
        // User is signed out
        handleSetUser(firebaseUser);
      }
    });
  }, []);

  return (
    <Routes>
      <Route path="/">
        <Route element={<DynamicAuthLayouts user={user} />}>
          <Route index element={<LandingPage />} />
          <Route path="library" element={<Library />}>
            <Route path="item/:bookId" element={<LibraryBook />} />
            <Route path=":userId" element={<AllBooks otherUsers />} />
            <Route path=":userId/item/:bookId" element={<LibraryBook otherUsers />} />
          </Route>
        </Route>
        <Route path="*" element={<Minimal />}>
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          {/* <Route path="password-reset/:id/:token" element={<PasswordReset />} /> */}
        </Route>
        <Route element={<ProtectedRoute user={user} />}>
          <Route path="*" element={<Main />}>
            <Route path="feed" element={<Feed />} />
            <Route path="library" element={<Library />}>
              <Route index element={<AllBooks />} />
            </Route>
            <Route path="book/:bookId" element={<div>Just The Book</div>} />
            <Route path="gallery" element={<Gallery />} />
            <Route path="import" element={<Import />} />
            <Route path="settings" element={<Settings />} />
          </Route>
        </Route>
        <Route path="*" element={<div>404 Not Found</div>} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
