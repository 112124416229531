import * as React from "react";

import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { AuthProvider } from "./contexts/authProvider";
import { LibraryProvider } from "./contexts/libraryProvider";
import { PublicLibraryProvider } from "./contexts/publicLibraryProvider";

import theme from "./theme";
import AppRoutes from "./routes";

const container = document.getElementById("root");
const root = createRoot(container!);

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREB_APIKEY,
  authDomain: process.env.REACT_APP_FIREB_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREB_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREB_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREB_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREB_APPID,
  measurementId: process.env.REACT_APP_FIREB_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// const tagManagerArgs = {
//   gtmId: 'AW-11040354133'
// }
// TagManager.initialize(tagManagerArgs)

root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <AuthProvider>
        <LibraryProvider>
          <PublicLibraryProvider>
            <AppRoutes />
          </PublicLibraryProvider>
        </LibraryProvider>
      </AuthProvider>
    </BrowserRouter>
  </ThemeProvider>,
);
