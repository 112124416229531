import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { AuthContext } from "../../contexts/authProvider";
import { LibraryContext } from "../../contexts/libraryProvider";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import ImportAccordion from "./ImportAccordion";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ForwardIcon from "@mui/icons-material/Forward";

const drawerWidth = 240;

import { getUserdata } from "../../api/resources/user";
import { getHighlights } from "../../api/resources/library";
import { postKindleImport } from "../../api/resources/import";
import { getTitles } from "../../api/resources/utilities";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const fileTypes = ["TXT"];


function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number },
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function Dashboard(props: Props) {
  const { window } = props;
  const { user, handleSetUserDetails } = React.useContext(AuthContext);
  const uid = user.firebaseData.auth.currentUser.uid;
  const [file, setFile] = useState(null);
  const { library, handleSetLibrary } = React.useContext(LibraryContext);
  const navigate = useNavigate();

  const [progress, setProgress] = React.useState(10);
  const [uploadInProgress, setUploadInProgress] = React.useState(false);
  const [uploadComplete, setUploadComplete] = React.useState(false);
  const [onwardBtnLoading, setOnwardBtnLoading] = React.useState(false);

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (progress === 100) {
        // setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
        setUploadComplete(true);
      } else if (progress >= 70) {
        setProgress((prevProgress) => prevProgress + 1);
      }
    }, 1069);
    return () => {
      clearInterval(timer);
    };
  }, [progress]);

  const handleChange = async (file) => {
    try {
      setUploadInProgress(true);

      const res = await postKindleImport(uid, file);

      if (res) {
        // Run to refresh images
        getTitles();

        setProgress(70);
      }

      let data = res.data;
      return data;

      return true;
    } catch (error) {
      return [];
    }
  };

  // const handleDragDropChange = async (file) => {
  //   try {
  //     const res = await postKindleImport(uid, file)

  //     // Run to refresh images
  //     // axios.get("/getTitles");

  //     if (res) {
  //       const res = await getHighlights(uid);

  //       const parsedMetadata = {};

  //       res["bookMetadata"].forEach((item) => {
  //         if (!item) {
  //           return;
  //         }

  //         parsedMetadata[item.title] = {
  //           image: item.image,
  //         };
  //       });

  //       handleSetLibrary(
  //         res.data.cards,
  //         parsedMetadata,
  //         res.data.likersMetadata,
  //         res.data.FavHighlights,
  //       );

  //       const userdataRes = await getUserdata(uid);

  //       handleSetUserDetails(userdataRes);

  //       navigate("/");
  //     }

  //     let data = res.data;
  //     return data;
  //   } catch (error) {
  //     return [];
  //   }
  // };

  const handleOnwardBtn = async () => {
    setOnwardBtnLoading(true);

    const res = await getHighlights(uid);

    const parsedMetadata = {};

    res["bookMetadata"].forEach((item) => {
      if (!item) {
        return;
      }

      parsedMetadata[item.title] = {
        image: item.image,
      };
    });

    handleSetLibrary(
      res.cards,
      parsedMetadata,
      res.likersMetadata,
      res.FavHighlights,
    );

    const userdataRes = await getUserdata(uid);

    handleSetUserDetails(userdataRes);

    setOnwardBtnLoading(false);

    navigate("/");
  };

  const getOverlineText = () => {
    if (uploadInProgress && progress <= 75) {
      return "Currently uploading your highlights";
    } else if (uploadInProgress && progress < 100) {
      return "Processing your upload";
    } else if (progress === 100) {
      return "Upload complete, click on the button above to visit your feed!";
    }

    return "";
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          textAlign: "center",
        }}
      >
        <Typography variant="h3">Import eReader highlights</Typography>

        <ImportAccordion />

        {/* <Box pt="1.5rem" display="flex" justifyContent="center">
          <FileUploader
            handleChange={handleDragDropChange}
            multiple={false}
            name="file"
            types={fileTypes}
          //onDrop={(file) => console.log(postImport(uid,file))}
          />
        </Box> */}
        <Box pt="1.5rem">
          <Box>
            {uploadComplete ? (
              <Button
                size="large"
                variant="outlined"
                component="label"
                startIcon={<ForwardIcon />}
                onClick={() => handleOnwardBtn()}
              >
                Venture Forth
              </Button>
            ) : (
              <Button
                size="large"
                variant="contained"
                component="label"
                startIcon={<UploadFileIcon />}
                disabled={uploadInProgress}
              >
                Upload Highlights
                <input
                  hidden
                  accept=".txt,.sqlite"
                  type="file"
                  onChange={(event) => handleChange(event.target.files[0])}
                />
              </Button>
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              maxWidth: "690px",
              margin: "0 auto",
              paddingTop: "1.3rem",
            }}
            hidden={!uploadInProgress && !uploadComplete && progress === 10}
          >
            <LinearProgressWithLabel value={progress} />
            <Typography variant="overline">{getOverlineText()}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
