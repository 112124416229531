import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Lottie from "lottie-react";
import empty from "./empty.json";

import FullPageNotice from "../../components/FullPageNotice";


export default function FullPageEmptyFollowingNotice(props) {

  return (
    <FullPageNotice imageSrc={null}>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Lottie animationData={empty} loop={false} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Box sx={{ display: "flex", alignContent: "center", justifyContent: "center", flexDirection: "column", height: "100%" }}>
              <Typography variant="h4">Come on in, the water's fine!</Typography>
              <Typography variant="body1" sx={{ fontStyle: "italic" }}>Join this book club to view what fellow readers are discussing about this book.</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </FullPageNotice>
  );
}
