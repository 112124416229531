import { truncate } from "fs";
import { axiosClient } from "../apiClient";

export async function putJoinBookClub(uid, bookId) {
  try {
    const res = await axiosClient.put("/joinBookClub", { params: { uid: uid, bookTitle: bookId } });

    return res.data;
  } catch (error) {
    return [];
  }
}


export async function putLeaveBookClub(uid, bookId) {
  try {
    const res = await axiosClient.put("/leaveBookClub", { params: { uid: uid, bookTitle: bookId } });

    return res.data;
  } catch (error) {
    return [];
  }
}


export async function getbookHighlights(uid, bookId) {
  try {
    const res = await axiosClient.get("/getbookHighlights", { params: { uid: uid, bookTitle: bookId } });

    return res.data;
  } catch (error) {
    return [];
  }
}


export async function getComments(bookId) {
  try {
    const res = await axiosClient.get("/getComments", { params: { bookID: bookId } });

    return res.data;
  } catch (error) {
    return [];
  }
}


export async function postComment(uid, comment, bookId) {
  try {
    const res = await axiosClient.post("/postcomment", { uid, comment, bookID: bookId });
    
    return true;
  } catch (error) {
    return [];
  }
}


export async function postChildComment(uid, comment, bookId, parentCommentId) {
  try {
    const res = await axiosClient.post("/postchildcomment", { uid, comment, bookID: bookId, commentID: parentCommentId });
    
    return true;
  } catch (error) {
    return [];
  }
}


export async function deleteComment(uid, commentId) {
  try {
    const res = await axiosClient.delete("/deletecomment", { params: { uid, id: commentId } });
    
    return true;
  } catch (error) {
    return false;
  }
}


export async function getBookClub(uid) {
  try {
    const res = await axiosClient.get("/getBookClub", { params: { uid } });

    return res.data;
  } catch (error) {
    return [];
  }
}