import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import noCover from "../../assets/img/nocover.png";

export default function FullPageNotice(props) {
  const { author, highlights, title, imageSrc, children } = props;

  return (
    <Card
      raised
      variant="outlined"
      sx={{ borderRadius: "20px", boxShadow: 3, width: "100%" }}
    >
      <Grid container spacing={1} sx={{ textAlign: "center", padding: { xs: "5rem 1.5rem", md: "8rem" } }}>
        { imageSrc ? (
          <Box 
            component="img"
            src={imageSrc}
            sx={{ width: "13rem", margin: "0 auto" }}
          />
        ) : null}
        {children}
      </Grid>
    </Card>
  );
}
