import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import StoreIcon from '@mui/icons-material/Store';
import Divider from "@mui/material/Divider";

import ShareDialog from "../ShareDialog";

import LoginDialog from "../LoginDialog";

import { AuthContext } from "../../contexts/authProvider";
import { PublicLibraryContext } from "../../contexts/publicLibraryProvider";

import { getBuyUrl } from "../../processes/utilities";

import { getUserdata } from "../../api/resources/user";
import {
  getPublicHighlights,
  putHighlightLike,
  putHighlightUnlike,
  deleteHighlights,
} from "../../api/resources/library";

export default function Likers(props) {
  const {
    isUnauth,
    highlightId,
    activeUid,
    profileUid,
    likers,
    likerProfiles,
    emptyLikes,
    otherUsers,
    highlightCreatorUserId,
    isHighlightCreator,
    isGalleryView,
    asin,
  } = props;

  const { user, handleSetUserDetails } = React.useContext(AuthContext);
  const {
    // profileUid,
    userdata,
    highlights,
    highlightsCount,
    handleSetUserdata,
    handleSetHighlights,
    handleSetHighlightsCount,
    handleResetPublicLibrary,
    handleSetProfileUid,
  } = React.useContext(PublicLibraryContext);

  const [shareDialogOpen, setShareDialogOpen] = React.useState(false);
  const [loginDialogOpen, setLoginDialogOpen] = React.useState(false);

  const navigate = useNavigate();

  const isPostLiked = () => {
    if (user && user.userDetails && user.userDetails.userinfo.length > 0) {
      return user.userDetails.userinfo[0]["likes"].includes(highlightId);
    }

    return false;
  };

  const handleHighlightsLike = async () => {
    if (activeUid) {
      await putHighlightLike(activeUid, highlightId, highlightCreatorUserId);

      if (otherUsers) {
        const res = await getPublicHighlights(activeUid, profileUid);
        const userdataRes = await getUserdata(activeUid);

        handleSetUserDetails(userdataRes);
        handleSetHighlights(res);
      } else {
        const userdataRes = await getUserdata(activeUid);

        handleSetUserDetails(userdataRes);
      }
    } else {
      setLoginDialogOpen(true);
    }
  };

  const handleHighlightsUnlike = async () => {
    if (activeUid) {
      await putHighlightUnlike(activeUid, highlightId);

      if (otherUsers) {
        const res = await getPublicHighlights(activeUid, profileUid);
        const userdataRes = await getUserdata(activeUid);

        handleSetUserDetails(userdataRes);
        handleSetHighlights(res);
      } else {
        const userdataRes = await getUserdata(activeUid);

        handleSetUserDetails(userdataRes);
      }
    }
  };

  const handleHighlightsDelete = async (highlightId) => {
    if (await deleteHighlights(highlightId)) {
      const userdataRes = await getUserdata(activeUid);

      handleSetUserDetails(userdataRes);
    }
  };

  const getLikerProfile = (uid) => {
    if (!likerProfiles) {
      return null;
    }

    const arrProfile = likerProfiles.find((item) => item.uid === uid);

    if (arrProfile) {
      return arrProfile;
    } else {
      return null;
    }
  };

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  };

  const getLikeButton = () => {
    const boolIsLiked = isPostLiked();

    return (
      <IconButton
        aria-label="like"
        onClick={() => {
          boolIsLiked ? handleHighlightsUnlike() : handleHighlightsLike();
        }}
      >
        {boolIsLiked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
      </IconButton>
    );
  };

  const getDeleteButton = () => {
    if (isHighlightCreator) {
      return (
        <IconButton
          aria-label="delete"
          onClick={() => handleHighlightsDelete(highlightId)}
        >
          <DeleteIcon />
        </IconButton>
      );
    }

    return null;
  };

  const getShareButton = () => {
    return (
      <>
        <IconButton aria-label="share" onClick={() => setShareDialogOpen(true)}>
          <ShareIcon />
        </IconButton>
        <ShareDialog
          {...props}
          open={shareDialogOpen}
          handleClose={() => setShareDialogOpen(false)}
        />
      </>
    );
  };
  
  const getShopButton = () => {
    if (!asin) {
      return null;
    }

    return (
      <>
        <IconButton aria-label="share" onClick={() => window.open(getBuyUrl(asin), "_blank", "noopener")}>
          <StoreIcon />
        </IconButton>
      </>
    );
  };

  const getLikers = () => {
    if (isUnauth) {
      return (
        <Box sx={{ margin: "auto", paddingRight: "0.5rem" }}>
          <Typography variant="overline">{`${likers} likes`}</Typography>
        </Box>
      );
    }

    return likers.map((item) => {
      const profile = getLikerProfile(item);
      let name = "Unknown Anon";
      let dp = null;

      if (profile) {
        name = profile.firstName.trim() + " " + profile.lastName.trim();
      }

      if (profile && profile["img"]) {
        if (Object.prototype.hasOwnProperty.call(profile, "img")) {
          if (profile["img"]["contentType"].includes("image/")) {
            dp = `data:${profile["img"].contentType};base64, ${profile["img"].img}`;
          }
        }
        return <Avatar alt={name} src={dp} />;
      } else {
        return <Avatar {...stringAvatar(name)} />;
      }
    });
  };

  if (emptyLikes) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          paddingTop: "0.5rem",
        }}
      >
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={1}
          justifyContent="flex-end"
        >
          {!isGalleryView ? (
            <Box sx={{ margin: "auto", paddingRight: "0.5rem" }}>
              <Typography variant="overline">No likes yet</Typography>
            </Box>
          ) : null}
          {getDeleteButton()}
          {getShopButton()}
          {getLikeButton()}
          {getShareButton()}
        </Stack>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          paddingTop: "0.5rem",
        }}
      >
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={1}
          justifyContent="flex-end"
        >
          <AvatarGroup max={6}>{getLikers()}</AvatarGroup>
          {getDeleteButton()}
          {getShopButton()}
          {getLikeButton()}
          {getShareButton()}
        </Stack>
      </Box>
      <LoginDialog
        isOpen={loginDialogOpen}
        handleClose={() => setLoginDialogOpen(false)}
      />
    </>
  );
}
