import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import BuyBookButton from "../Buttons/BuyBook";
import Lottie from "lottie-react";
import empty from "./empty.json";

import FullPageNotice from "../../components/FullPageNotice";

export default function FullPageEmptyFollowingNotice(props) {
  const { bookId, asin } = props;

  return (
    <FullPageNotice imageSrc={null}>
      <Grid item xs={12} md={4}>
        <Lottie animationData={empty} loop={false} />
      </Grid>
      <Grid item xs={12} md={8}>
        <Box sx={{ display: "flex", alignContent: "center", justifyContent: "center", flexDirection: "column", height: "100%" }}>
          <Typography variant="h4">No Highlights</Typography>
          <Typography variant="body1" sx={{ fontStyle: "italic" }}>You do not have any highights for this book yet.</Typography>
          <Box sx={{ paddingTop: "1rem" }}>
            <BuyBookButton buttonText="Change that" bookId={bookId} asin={asin} size="large" />
          </Box>
        </Box>
      </Grid>
    </FullPageNotice>
  );
}
