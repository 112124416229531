import * as React from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const FavouriteCard = React.forwardRef((props, ref) => {
  const { randomNumber, highlight, author, title } = props;

  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }

  const images = importAll(require.context('../../assets/imgQuotes', false, /\.(png|jpe?g|svg)$/));

  return (
    <Box ref={ref} sx={{ backgroundImage: `url(${images[`${randomNumber}.jpg`].default})`, backgroundSize: "cover", width: "100%", height: {xs: "680px", sm: "720px"}, display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "20px" }}>
      <Box sx={{ backgroundColor: "rgb(255, 255, 255, 0.3)", borderRadius: "20px", margin: "1rem", padding: "0.8rem" }}>
        <Box>
          <Typography gutterBottom variant="body2" sx={{ fontFamily: "Lora, serif", fontStyle: "italic", textAlign: "center", fontSize: "1.3rem", textOverflow: "ellipsis", overflow: "hidden", maxHeight: "39rem" }}>{highlight}</Typography>
          <Typography variant="body2" sx={{ textAlign: "center", fontStyle: "italic" }}>{author}</Typography>
          <Typography variant="body2" sx={{ textAlign: "center", fontWeight: "bold" }}>{title}</Typography>
        </Box>
      </Box>
    </Box>
  )
});

export default FavouriteCard;
