import { toast } from "react-toastify";
import { getAuth } from "firebase/auth";
import axios from "axios";

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_APIBASEURL ? process.env.REACT_APP_APIBASEURL : "http://localhost:8000",
  // headers: {
  //   'Accept': 'application/json',
  //   'Content-Type': 'application/json'
  // }
});

export const uninterceptedAxiosClient = axios.create({
  baseURL: process.env.REACT_APP_APIBASEURL ? process.env.REACT_APP_APIBASEURL : "http://localhost:8000",
  // headers: {
  //   'Accept': 'application/json',
  //   'Content-Type': 'application/json'
  // }
});

axiosClient.interceptors.request.use(
  async function (request) {
    const auth = getAuth();
    const user = auth.currentUser;

  
    if (user) {
      try {
        const token = await user.getIdToken(false); // Force refresh is false
        request.headers["Authorization"] = token;
      } catch (error) {
        console.log("Error obtaining auth token in interceptor, ", error);
      }
    }
  
    return request;
  }
)

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let res = error.response;
    if (res.status == 401) {
      window.location.href = "https://example.com/login";
    }
    
    toast.error('The application is facing connectivity issues. Please try again in a bit!', {
      position: "top-right",
      toastId: "connectivity-error",
      autoClose: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

    return Promise.reject(error);
  }
);
