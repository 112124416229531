import * as React from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import LoadingButton from '@mui/lab/LoadingButton';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';

import LoadingIndicator from '../../components/LoadingIndicator';
import UserImage from '../../components/UserImage';

import { getTopUsers } from '../../api/resources/utilities';
import { putLibraryFollow, putLibraryUnfollow } from '../../api/resources/library';

function FollowLoadingButton(props) {
  const { activeUid, uidToFollow, isFollowing, getTopUsersRequest } = props;

  const [btnLoading, setBtnLoading] = React.useState(false);

  const handleFollow = async (activeUid, uidToFollow) => {
    setBtnLoading(true);

    const res = isFollowing ?  await putLibraryUnfollow(activeUid, uidToFollow) : await putLibraryFollow(activeUid, uidToFollow);
    
    await getTopUsersRequest();
    setBtnLoading(false);
  }

  return (
    <LoadingButton
      color={isFollowing ? "error" : "primary"}
      loading={btnLoading}
      loadingPosition="start"
      startIcon={isFollowing ? <PersonRemoveAlt1Icon /> : <PersonAddAltIcon />}
      variant="outlined"
      onClick={() => handleFollow(activeUid, uidToFollow)}
    >
      {isFollowing ? "Unfollow" : "Follow"}
    </LoadingButton>
  )
}

export default function UserFollowSuggestions(props) {
  const { activeUid } = props;
  const [topUsers, setTopUsers] = React.useState(null);

  const getTopUsersRequest = async () => {
    const res = await getTopUsers();

    if (Object.prototype.hasOwnProperty.call(res, "topUserMetadata")) {
      setTopUsers(res["topUserMetadata"]);
    } else {
      setTopUsers([]);
    }
  };

  React.useEffect(() => {
    try {
      getTopUsersRequest();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getFollowRecsContent = () => {
    if (!topUsers) {
      return (
        <Box sx={{ maxWidth: "20em", margin: "0 auto" }}>
          <LoadingIndicator />
        </Box>
      );
    }
  
    if (topUsers.length === 0) {
      return "NO RECOMMENDATIONS";
    } else {
      return topUsers.map((item) => (
        <ListItem
          key={item.uid}
          secondaryAction={
            <FollowLoadingButton
              activeUid={activeUid}
              uidToFollow={item.uid}
              isFollowing={item.followers.find(item => activeUid === item)}
              getTopUsersRequest={getTopUsersRequest}
            />
          }
        >
          <ListItemAvatar sx={{ paddingRight: "1rem" }}>
            <UserImage firstName={item.firstName} lastName={item.lastName} imageMetadata={item.img} isLarge />
          </ListItemAvatar>
          <ListItemText primary={item.firstName + " " + item.lastName} secondary="A Fellow Blipps User" />
        </ListItem>
      ))
    }
  }


  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h3" sx={{ textAlign: "center" }}>Find some users to follow</Typography>
        <Typography variant="body2" sx={{ textAlign: "center", fontStyle:"italic" }}>Your Blipps experience is better when you follow other readers! See what other readers are highlighting and discover impactful highlights which may resonate with you as well.</Typography>
      </Grid>
      <Grid item xs={12}>
        {getFollowRecsContent()}
      </Grid>
    </Grid>
  );
}