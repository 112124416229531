import * as React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import FollowerTabs from './FollowerTabs';

export default function FollowerDialog(props) {
  const { openTab, handleClose, profileUid, followers, following, bookClub } = props;

  return (
    <div>
      <Dialog
        open={openTab !== "closed"}
        onClose={() => handleClose("closed")}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            minHeight: "80%"
          }
        }}  
      >
        <DialogContent>
          <FollowerTabs
            openTab={openTab}
            profileUid={profileUid}
            handleClose={() => handleClose("closed")}
            followers={followers}
            following={following}
            bookClub={bookClub}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose("closed")}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}