import React, { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import { Grid, Box } from "@mui/material";
import Card from '@mui/material/Card';

import { postUserRegistration } from "../../api/resources/user";
import { AuthContext } from "../../contexts/authProvider";

import styles from "./styles.module.css";

const Signup = () => {
  const { user } = React.useContext(AuthContext);
  
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    uid: "",
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };
  
  React.useEffect(() => {
    if (user.isLoggedIn) {
      navigate("/feed");
    }
  }, [user]);

  const handleSubmit = async (
    e: React.FormEvent<HTMLInputElement>,
  ): Promise<void> => {
    e.preventDefault();
    const auth = getAuth();

    createUserWithEmailAndPassword(auth, data.email, data.password)
      .then(async(userCredential) => {
        // Signed in
        const uid = userCredential.user.uid;
        data.uid = uid;
        const user = userCredential.user;
        const res = await postUserRegistration(data)
			  //navigate("/login");
        //console.log(res.message);
        navigate("/feed");
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        if (error.code.includes("auth/email-already-in-use")) {
          setError("The email is already in use.");
        } else if (error.code.includes("auth/weak-password")) {
          setError("Please use a password of at least six characters.");
        }
        // ..
      });
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh', justifyContent: 'center' }}
    >
      <Grid item xs={12}>
        <Card sx={{ maxWidth: "900px", borderRadius: "20px", boxShadow: 3 }}>
          <Grid container>
            <Grid xs={12} md={4} sx={{ textAlign: "center", padding: "1rem", backgroundColor: "#3bb19b", display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
              <Box sx={{ }}>
                <h1>Welcome Back</h1>
                <Link to="/login">
                  <button type="button" className={styles.white_btn}>
                    Sign in
                  </button>
                </Link>
              </Box>
            </Grid>
            <Grid xs={12} md={8} sx={{ textAlign: "center", padding: "1rem", display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
              <form className={styles.form_container} onSubmit={handleSubmit}>
                <h1>Create Account</h1>
                <input
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  onChange={handleChange}
                  value={data.firstName}
                  required
                  className={styles.input}
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  onChange={handleChange}
                  value={data.lastName}
                  required
                  className={styles.input}
                />
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={handleChange}
                  value={data.email}
                  required
                  className={styles.input}
                />
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={handleChange}
                  value={data.password}
                  required
                  className={styles.input}
                />
                {error && <div className={styles.error_msg}>{error}</div>}
                <button type="submit" className={styles.green_btn}>
                  Sign Up
                </button>
              </form>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>

    // <Box className={styles.signup_container}>
    //   <Grid container className={styles.signup_form_container}>
    //     <Grid item direction="column" className={styles.left}>
    //       <h1>Welcome Back</h1>
    //       <Link to="/login">
    //         <button type="button" className={styles.white_btn}>
    //           Sign in
    //         </button>
    //       </Link>
    //     </Grid>
    //     <Grid item className={styles.right}>
          
    //     </Grid>
    //   </Grid>
    // </Box>
  );
};

export default Signup;
