import { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import Box from "@mui/material/Box";

import axios from "axios";
import styles from "./styles.module.css";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        // ..
        setMsg("A password reset email will be dispatched if the email exists.")
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        setMsg("A password reset email will be dispatched if the email exists.")
      });
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh', justifyContent: 'center' }}
    >
      <Grid item xs={12}>
        <Card sx={{ maxWidth: "860px", borderRadius: "20px", boxShadow: 3 }}>
          <Grid container>
            <Grid xs={12} sx={{ textAlign: "center", padding: "1rem" }}>
              <form  onSubmit={handleSubmit}>
                <h1>Forgot Password</h1>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                  className={styles.input}
                />
                {error && <div className={styles.error_msg}>{error}</div>}
                {msg && <div className={styles.success_msg}>{msg}</div>}
                <div>
                  <button type="submit" className={styles.green_btn}>
                    Submit
                  </button>
                  <button className={styles.white_btn} onClick={() => navigate("/login")}>
                    Login
                  </button>
                </div>
              </form>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
