import { axiosClient, uninterceptedAxiosClient } from "../apiClient";


export async function getTitles() {
  try {
    let res = await uninterceptedAxiosClient.get("/getTitles");

    return res.data;
  } catch (error) {
    return [];
  }
}


export async function getSearch(uid, query) {
  try {
    const res = await axiosClient.get(`/search`, { params: { q: query, uid: uid } });

    return res.data;
  } catch (error) {
    return [];
  }
}


export async function getTopUsers() {
  try {
    const res = await axiosClient.get("/topUsers");

    return res.data;
  } catch (error) {
    return [];
  }
}


export async function getTopBooks() {
  try {
    const res = await axiosClient.get("/topBooks");

    return res.data;
  } catch (error) {
    return [];
  }
}


export async function getNotifications(uid) {
  try {
    const res = await axiosClient.get("/getNotifications", { params: {uid} });

    return res.data;
  } catch (error) {
    return [];
  }
}


export async function postReadNotifications(uid) {
  try {
    const res = await axiosClient.post("/readNotifications", { uid });

    return true;
  } catch (error) {
    return false;
  }
}


export async function getRandomHighlights() {
  try {
    const res = await axiosClient.get("/getRandomHighlights");

    return res.data;
  } catch (error) {
    return [];
  }
}
