import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import logo from "../../assets/img/logos/logo.png";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";

export default function LoginDialog(props) {
  const { isOpen, handleClose } = props;

  const [isLogin, setisLogin] = React.useState(true);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            minHeight: "90%",
          },
        }}
      >
        <DialogContent>
          <Grid container>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Box
                component="img"
                src={logo}
                sx={{
                  maxWidth: "8rem",
                  borderRadius: "50%",
                  margin: "3rem 0 3rem 0",
                }}
              />
              <Typography
                variant="h1"
                sx={{ fontSize: "2rem", paddingBottom: "1.5rem" }}
              >
                {isLogin ? "Log In" : "Sign Up"}
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontStyle: "italic", paddingBottom: "1rem" }}
              >
                This action requires a Blipps account
              </Typography>
              <Typography>
                {isLogin
                  ? "If you do not have an account yet"
                  : "Blipps is better with you around!"}
              </Typography>
              <Button
                disableRipple
                color="secondary"
                size="large"
                onClick={() => setisLogin(!isLogin)}
              >
                {isLogin ? "Create an Account" : "Return to login"}
              </Button>
            </Grid>
            {isLogin ? <LoginForm /> : <SignupForm />}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
