import * as React from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button';

import SearchBar from "../../components/SearchBar";

import { AuthContext } from "../../contexts/authProvider";

const drawerWidth = 0;

export default function LandingLayout() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [mobileSearchOpen, setMobileSearchOpen] = React.useState(false);
  const [notAuthenticated, setNotAuthenticated] = React.useState(null);
  const { user } = React.useContext(AuthContext);

  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  React.useEffect(() => {
    if (user.isLoggedIn) {
      navigate("/feed");
    } else if (!user.isLoggedIn && user.firebaseData === null) {
      setNotAuthenticated(false);
    }
  }, [user]);

  if (notAuthenticated == false && notAuthenticated !== null) {
    return (
      <>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            color="blippsWhite"
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px` },
            }}
          >
            <Toolbar sx={{ display: { xs: 'none', md: 'inherit' } }}>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "center", position: "absolute" }}>
                <SearchBar isUnauth />
              </Box>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
                <Button variant="contained" onClick={() => navigate("/login")} >Log In</Button>
              </Box>
            </Toolbar>
  
            <Toolbar sx={{ display: { md: 'none' }, justifyContent: { xs: "space-between", sm: "flex-end"} }}>
              {
                mobileSearchOpen ? (
                  <Box sx={{ width: "100%", justifyContent: "end", display: "flex" }}>
                    <SearchBar isUnauth autofocus="autofocus" />
                  </Box>
                ) : null
              }
              <IconButton onClick={() => setMobileSearchOpen(!mobileSearchOpen)}>
                {
                  mobileSearchOpen ? (
                    <CancelIcon />
                  ) : (
                    <SearchIcon /> 
                  )
                }
              </IconButton>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
                <Button variant="contained" onClick={() => navigate("/login")} >Log In</Button>
              </Box>
            </Toolbar>
          </AppBar>
          {/* <AppDrawer
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          /> */}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
          >
            <Toolbar />
            <Outlet />  
          </Box>
        </Box>
      </>
    );
  }

  return null;
}
