import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import Box from "@mui/material/Box";

import styles from "./styles.module.css";

import logo from "../../assets/img/logos/logo.png";

import { AuthContext } from "../../contexts/authProvider";

const Login = () => {
  const [data, setData] = React.useState({ email: "", password: "" });
  const [error, setError] = React.useState("");
  const { user } = React.useContext(AuthContext);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (user.isLoggedIn) {
      navigate("/feed");
    }
  }, [user]);

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const url = "http://localhost:8000/api/auth";
  //     const { data: res } = await axios.post(url, data);

  //     navigate("/library");
  //   } catch (error) {
  //     console.log(error);
  //     if (
  //       error.response &&
  //       error.response.status >= 400 &&
  //       error.response.status <= 500
  //     ) {
  //       setError(error.response.data.message);
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const auth = getAuth();
    signInWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        // ...
        navigate("/feed");
      })
      .catch((error) => {
        if (error.message.includes("auth/")) {
          setError("Invalid credentials.");
        }
        else {
          setError(error.message);
        }
      });
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh', justifyContent: 'center' }}
    >
      <Grid item xs={12}>
        <Card sx={{ maxWidth: "860px", borderRadius: "20px", boxShadow: 3 }}>
          <Grid container>
            <Grid xs={12} md={8} sx={{ textAlign: "center", padding: "1rem" }}>
              <form onSubmit={handleSubmit}>
                <Box
                  component="img"
                  src={logo}
                  sx={{ maxWidth: "8rem", borderRadius: "50%", margin: "3rem 0" }}
                />
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={handleChange}
                  value={data.email}
                  required
                  className={styles.input}
                />
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={handleChange}
                  value={data.password}
                  required
                  className={styles.input}
                />
                {/* <Link to="/forgot-password" style={{ alignSelf: "flex-start" }}>
                  <p style={{ padding: "0 15px" }}>Forgot Password ?</p>
                </Link> */}
                {error && <div className={styles.error_msg}>{error}</div>}
                <div>
                  <button type="submit" className={styles.green_btn}>
                    Log in
                  </button>
                  <button className={styles.green_btn_hollow} onClick={() => {navigate("/forgot-password")}}>
                    Password Reset
                  </button>
                </div>
              </form>
            </Grid>
            <Grid xs={12} md={4} sx={{ textAlign: "center", padding: "1rem", backgroundColor: "#3bb19b", display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
              <Box className={styles.right_header}>
                <h1>New Here?</h1>
                <Link to="/signup">
                  <button type="button" className={styles.white_btn}>
                    Sign Up
                  </button>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Login;
