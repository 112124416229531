import React, { useState } from "react";

export const LibraryContext = React.createContext({
  library: {},
  setLibrary: () => {},
});

function LibraryProvider(props) {
  const [library, setLibrary] = useState({
    highlights: null,
    books: null,
    highlightCounts: null,
    likersMetadata: null,
    favourites: null,
  });

  const [followingLibrary, setFollowingLibrary] = useState(null);

  const handleSetLibrary = (highlights, books, likersMetadata, favourites) => {
    let verifiedHighlights = [];
    let verifiedBooks = {};
    let verifiedLikersMetadata = [];
    let verifiedFavourites = [];

    if (highlights) {
      verifiedHighlights = highlights;
    }

    if (books) {
      verifiedBooks = books;
    }

    if (likersMetadata) {
      verifiedLikersMetadata = likersMetadata;
    }

    if (favourites) {
      verifiedFavourites = favourites;
    }

    setLibrary({
      highlights: verifiedHighlights,
      books: verifiedBooks,
      highlightCounts: library.highlightCounts,
      likersMetadata: verifiedLikersMetadata,
      favourites: verifiedFavourites,
    });
  };

  const handleSetHighlightCounts = (highlightCounts) => {
    let verifiedHighlightCounts = [];

    if (highlightCounts) {
      verifiedHighlightCounts = highlightCounts;
    }

    setLibrary({
      highlights: library.highlights,
      books: library.books,
      highlightCounts: verifiedHighlightCounts,
      likersMetadata: library.likersMetadata,
      favourites: library.favourites,
    });
  };

  const handleSetFollowingLibrary = (followingData) => {
    setFollowingLibrary(followingData);
  }

  const handleResetLibrary = () => {
    setFollowingLibrary(null);

    setLibrary({
      highlights: null,
      books: null,
      highlightCounts: null,
      likersMetadata: null,
      favourites: null,
    });
  };

  const value = { library, followingLibrary, handleSetLibrary, handleSetHighlightCounts, handleSetFollowingLibrary, handleResetLibrary };

  return <LibraryContext.Provider value={value} {...props} />;
}

export { LibraryProvider };
