import * as React from "react";
import Button from "@mui/material/Button";
import PostAddIcon from '@mui/icons-material/PostAdd';

import { styled } from "@mui/material/styles";

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "black",
  backgroundColor: "rgb(255, 216, 20)",
  "&:hover": {
    backgroundColor: "rgb(247, 202, 0)",
  },
}));

export default function AddHighlightButton(props) {
  const { bookId, asin, buttonText } = props;

  return (
    <ColorButton
      {...props}
      variant="contained"
      startIcon={<PostAddIcon />}
    >
      Add Highlight
    </ColorButton>
  );
}
