import * as React from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';

import FullPageNotice from "../../components/FullPageNotice";
import AddHighlightButton from "../../components/Buttons/AddHighlight";
import NewHighlightsDialog from "../../components/NewHighlightDialog";

import addFilesSvg from "../../assets/img/undraw_add_files_re_v09g.svg";
import FileUploader from "../FileUploader";
import ImportAccordion from "../../containers/Import/ImportAccordion";

export default function FullPageOnboardNotice(props) {
  const { author, highlights, title, image, nestedOnboarding, uid } = props;

  const [addHighlightsOpen, setAddHighlightsOpen] = React.useState(false);

  return (
    <>
      <FullPageNotice imageSrc={addFilesSvg}>
        <Grid item xs={12}>
          {
            nestedOnboarding ? (
              <>
                <Typography gutterBottom variant="h3" sx={{ fontSize: { xs: "1.8rem", md: "3rem" } }}>Import your highlights</Typography>
                <Typography variant="body1">If you have an eReader, we recommend uploading your highlights to Blipps to easily browse them all in one place!</Typography>
              </>
            ) : (
              <>
                <Typography gutterBottom variant="h3" sx={{ fontSize: { xs: "1.8rem", md: "3rem" } }}>You have no highlights yet</Typography>
                <Typography variant="body1">Import highlights from your eReader to begin</Typography>
              </>
            )
          }
        </Grid>
        <Grid item xs={12}>
          <ImportAccordion />
        </Grid>
        <Grid item xs={12}>
          <FileUploader />
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" sx={{ margin: "2rem 0 1rem 0" }}>No eReader?</Divider>
          <AddHighlightButton onClick={() => setAddHighlightsOpen(true)} />
        </Grid>
      </FullPageNotice>
      <NewHighlightsDialog open={addHighlightsOpen} handleClose={() => setAddHighlightsOpen(false)} activeUid={uid} />
    </>
  );
}
