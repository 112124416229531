export const getUid = (firebaseData, isUnauth = null) => {
  if (isUnauth) {
    return null;
  }

  if (!firebaseData || Object.keys(firebaseData).length === 0) {
    return null;
  }

  return firebaseData.auth.currentUser.uid;
}