import * as React from 'react';
import Lottie from "lottie-react";

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import FullPageNotice from '../../components/FullPageNotice';

import NewUserStepper from './NewUserStepper';
import newUser from './newUser.json';

export default function NewUserOnboarding(props) {
  const { activeUid, hasBookClub, hasFollowing, hideOnboarding } = props;

  return (
    <FullPageNotice>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box sx={{ maxWidth: "35rem", marginTop: "-10rem", marginBottom: "-4.5rem" }}>
              <Lottie animationData={newUser} loop={true} />
            </Box>
            <Typography variant="h1" sx={{ textAlign: "center", fontSize: "5.6em" }}>Welcome to Blipps</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <NewUserStepper activeUid={activeUid} hasBookClub={hasBookClub} hasFollowing={hasFollowing} hideOnboarding={hideOnboarding} />
        </Grid>
      </Grid>
    </FullPageNotice>
  );
}