import * as React from "react";
import { axiosClient } from "../../api/apiClient";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import HighlightsCard from "../../components/HighlightsCard";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import LoadingIndicator from "../../components/LoadingIndicator";
import FullPageOnboardNotice from "../../components/FullPageNotice_Onboard";
import FullPageEmptyFollowingNotice from "../../components/FullPageNotice_NothingHere";
import { AuthContext } from "../../contexts/authProvider";
import { LibraryContext } from "../../contexts/libraryProvider";
import AddHighlightButton from "../../components/Buttons/AddHighlight";
import NewHighlightsDialog from "../../components/NewHighlightDialog";
import NewUserOnboarding from "../NewUserOnboarding";

import { getHighlights, getFollowingHighlights } from "../../api/resources/library";

export default function Feed() {
  const { library, followingLibrary, handleSetLibrary, handleSetFollowingLibrary } = React.useContext(LibraryContext);

  const { user } = React.useContext(AuthContext);

  const uid = user.firebaseData.auth.currentUser.uid;

  const [value, setValue] = React.useState('1');
  const [addHighlightsOpen, setAddHighlightsOpen] = React.useState(false);
  const [onboardingHidden, setOnboardingHidden] = React.useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const getHighlightsRequest = async () => {
    const res = await getHighlights(uid);

    const parsedMetadata = {};

    res["bookMetadata"].forEach((item) => {
      if (!item) {
        return;
      }

      parsedMetadata[item.title] = {
        image: item.image,
        asin: item.asin,
      };
    });

    handleSetLibrary(res.cards, parsedMetadata, res.likersMetadata, res.FavHighlights);
  };
  
  const getFollowingHighlightsRequest = async () => {
    const res = await getFollowingHighlights(uid);

    handleSetFollowingLibrary(res);
  };

  const handleHideOnboarding = () => {
    getHighlightsRequest();
    getFollowingHighlightsRequest();

    setOnboardingHidden(true);
  }
  
  React.useEffect(() => {
    try {
      getHighlightsRequest();
      getFollowingHighlightsRequest();
    } catch (error) {
      console.log(error);
    }
  }, [user]);

  const getName = () => {
    if (user.userDetails) {
      if (Object.prototype.hasOwnProperty.call(user.userDetails, "userinfo")) {
        if (user.userDetails.userinfo.length > 0) {
          return user.userDetails["userinfo"][0]["firstName"] + " " + user.userDetails["userinfo"][0]["lastName"];
        }

        return "The Nameless";
      }
    }
  }

  const hasFollowing = () => {
    if (user.userDetails) {
      if (Object.prototype.hasOwnProperty.call(user.userDetails, "userinfo")) {
        if (user.userDetails.userinfo.length > 0) {
          return user.userDetails["userinfo"][0]["following"].length > 0;
        }

        return false;
      }
    }
  }

  const hasBookClub = () => {
    if (user.userDetails) {
      if (Object.prototype.hasOwnProperty.call(user.userDetails, "userinfo")) {
        if (user.userDetails.userinfo.length > 0) {
          return user.userDetails["userinfo"][0]["bookclub"].length > 0;
        }

        return false;
      }
    }
  }

  const getFollowingBookImage = (arrBookMetadata, title) => {
    const book = arrBookMetadata.find((item) => item.title === title)

    if (book) {
      return book["image"];
    }

    return null;
  }

  const getFollowingBookAsin = (arrBookMetadata, title) => {
    const book = arrBookMetadata.find((item) => item.title === title)

    if (book) {
      return book["asin"];
    }

    return null;
  }

  const getFollowingCards = () => {
    let arrFollowingHighlights = [];

    if (followingLibrary) {
      for (const user in followingLibrary) {
        const name = followingLibrary[user]["name"];
        const followingUid = followingLibrary[user]["uid"];
        const cards = followingLibrary[user]["cards"];
        const bookMetadata = followingLibrary[user]["bookMetadata"];

        arrFollowingHighlights.push(cards.map((item) => {
          return (
            <Grid item xs={12} key={item._id}>
              <HighlightsCard
                activeUid={uid}
                highlightId={item._id}
                author={item.author}
                highlights={item.highlights}
                title={item.title}
                image={getFollowingBookImage(bookMetadata, item.title)}
                asin={getFollowingBookAsin(bookMetadata, item.title)}
                firstName={name}
                highlightCreatorUserId={followingUid}
                isHighlightCreator={followingUid === uid}
                creatorUid={item.uid}
                likers={item.likes}
                likerProfiles={followingLibrary[user]["likersMetadata"]}
                // creatorProfiles={bookClubData.userMetadata}
              />
            </Grid>
          );
        }));
      }
    }

    return arrFollowingHighlights;
  };

  const getBookImage = (jsonBook) => {
    if (!jsonBook) {
      return null;
    }

    if (Object.prototype.hasOwnProperty.call(jsonBook, "image")) {
      return jsonBook["image"];
    }

    return null;
  }
  
  const getBookAsin = (jsonBook) => {
    if (!jsonBook) {
      return null;
    }

    if (Object.prototype.hasOwnProperty.call(jsonBook, "asin")) {
      return jsonBook["asin"];
    }

    return null;
  }

  const getBookDetails = (title) => {
    if (library && library.books) {
      if (Object.prototype.hasOwnProperty.call(library.books, title)) {
        return library.books[title];
      }
    }

    return null;
  }
  
  const getPersonalCards = () => {
    if (library.highlights && library.highlights.length !== 0) {
      return library.highlights.map((item) => {
        const jsonBookDetails = getBookDetails(item.title);
        return (
          <Grid item xs={12} key={item._id}>
            <HighlightsCard
              activeUid={uid}
              highlightId={item._id}
              author={item.author}
              highlights={item.highlights}
              title={item.title}
              image={getBookImage(jsonBookDetails)}
              asin={getBookAsin(jsonBookDetails)}
              firstName={getName()}
              highlightCreatorUserId={uid}
              isHighlightCreator={true}
              // creatorUid={item.uid}
              likers={item.likes}
              likerProfiles={library.likersMetadata}
            />
          </Grid>
        );
      });
    }

    return null;
  };

  const getFollowingContent = () => {
    if (followingLibrary && Object.keys(followingLibrary).length !== 0) {
      const followingCards = getFollowingCards();
      let intValueMoreThanOne = 0;

      for (const itemArray of followingCards) {
        if (itemArray.length > intValueMoreThanOne) {
          intValueMoreThanOne = itemArray.length;
          break;
        }
      }

      if (intValueMoreThanOne === 0) {
        return <FullPageEmptyFollowingNotice />;
      }

      return (
        <Grid container spacing={2}>
          {followingCards}
        </Grid>
      );
    } else if (followingLibrary && Object.keys(followingLibrary).length === 0) {
      return <FullPageEmptyFollowingNotice />;
    }

    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "300px" }}>
          <LoadingIndicator />
        </Box>
      </Box>
    );
  };

  const getPersonalContent = () => {
    if (library.highlights && library.highlights.length !== 0) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <AddHighlightButton onClick={() => setAddHighlightsOpen(true)} />
            </Box>
          </Grid>
          {getPersonalCards()}
        </Grid>
      );
    }

    if (Array.isArray(library.highlights) && library.highlights.length === 0) {
      if (hasBookClub() && hasFollowing() || onboardingHidden) {
        return <FullPageOnboardNotice uid={uid} />;
      }

      return <NewUserOnboarding activeUid={uid} hasBookClub={hasBookClub()} hasFollowing={hasFollowing()} hideOnboarding={() => handleHideOnboarding()} />;
    }

    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "300px" }}>
          <LoadingIndicator />
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h3">Feed</Typography>
          <Typography paragraph>A timeline of your Blipps journey</Typography>
        </Box>
      </Box>
      <Divider sx={{ marginBottom: "1rem" }} />
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="View Personal" value="1" />
            <Tab label="View Following" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">{getPersonalContent()}</TabPanel>
        <TabPanel value="2">{getFollowingContent()}</TabPanel>
      </TabContext>
      <NewHighlightsDialog open={addHighlightsOpen} handleClose={() => setAddHighlightsOpen(false)} activeUid={uid} />
    </Box>
  );
}
