import * as React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import noCover from "../../assets/img/nocover.png";
import Likers from "../Likers";

export default function HighlightsCard(props) {
  const navigate = useNavigate();

  const {
    isUnauth,
    highlightId,
    activeUid,
    author,
    highlights,
    title,
    image,
    firstName,
    highlightCreatorUserId,
    isHighlightCreator,
    likers,
    likerProfiles,
  } = props;

  const getLikers = () => {
    if ((likers && likers.length > 0) || isUnauth) {
      return <Likers {...props} />;
    }

    return <Likers {...props} emptyLikes />;
  };

  const getHighlightCreator = () => {
    if (isUnauth) {
      return <Box />;
    }

    if (!isHighlightCreator) {
      return (
        <Typography
          component="span"
          onClick={() => navigate(`/library/${highlightCreatorUserId}`)}
          sx={{ fontSize: 14, fontWeight: "bold", cursor: "pointer" }}
          color="text.secondary"
        >
          {`${firstName} added a highlight`}
        </Typography>
      );
    } else {
      return (
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {`You added a highlight`}
        </Typography>
      );
    }
  };

  return (
    <Box sx={{ minWidth: "100%" }}>
      <Card
        raised
        variant="outlined"
        sx={{ borderRadius: "20px", boxShadow: 3, display: "flex" }}
      >
        <CardContent sx={{ width: "100%" }}>
          <Grid spacing={1} container>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignContent: "center",
                }}
              >
                {getHighlightCreator()}
                <Box sx={{}}>{getLikers()}</Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                sx={{
                  width: "100%",
                  borderRadius: "10px",
                  maxWidth: "169px",
                  boxShadow: 3,
                  cursor: "pointer"
                }}
                onClick={() => navigate(`/library/item/${title}`)}
                src={image ? image : noCover}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={9}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    mb: 1.5,
                    textAlign: "center",
                    fontFamily: "Lora",
                    fontSize: "1.5rem",
                    margin: "auto",
                  }}
                  variant="body2"
                >
                  {`"${highlights}"`}
                </Typography>
              </Box>
              <Box sx={{ textAlign: "right", marginTop: "2rem" }}>
                <Typography
                  variant="overline"
                  component="div"
                  sx={{
                    lineHeight: "unset",
                    fontSize: "1.2rem",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/library/item/${title}`)}
                >
                  {title}
                </Typography>
                <Typography
                  variant="overline"
                  color="text.secondary"
                  sx={{ lineHeight: "unset" }}
                >
                  {author}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}
