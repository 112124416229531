import * as React from 'react';

import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ReplyIcon from '@mui/icons-material/Reply';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';

import UserImage from '../UserImage';
import CommentComposer from './CommentComposer';
import FullPageNoDiscussionsNotice from '../FullPageNotice_NoDiscussions';

import { deleteComment } from '../../api/resources/bookClub';

const theme = createTheme({
  components: {
    // Name of the component
    MuiListItem:{
      styleOverrides: {
        root: {
          "&:hover .MuiListItemSecondaryAction-root": {
            visibility: 'visible',
          }
        }
      },
    },
    MuiListItemSecondaryAction: {
      styleOverrides: {
        root: {
          visibility: 'hidden',
        },
      },
    },
  },
});

const getProfile = (arrUserProfile, userUid) => {
  if (!arrUserProfile) {
    return {};
  }

  return arrUserProfile.find((item) => item.uid === userUid);
}

const getNameFromProfile = (objUserProfile) => {
  if (!objUserProfile) {
    return null;
  }

  return objUserProfile.firstName + " " + objUserProfile.lastName;
}

const handleCommentDelete = async (activeUid, commentId, getCommentsRequest) => {
  let res = await deleteComment(activeUid, commentId);

  if (res) {
    getCommentsRequest();
  }

  return true;
}


const ListItemWithHoverActions = (props) => (
  <ThemeProvider theme={theme}>
    <ListItem {...props} />
  </ThemeProvider>
);

function CommentActions(props) {
  const { setReplyOpen, isChild, isCommentOwner, commentId, activeUid, getCommentsRequest } = props;
  return (
    <Stack
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      spacing={1}
      justifyContent="flex-end"
    >
      {
        isCommentOwner ? (
          <IconButton onClick={() => handleCommentDelete(activeUid, commentId, getCommentsRequest)}>
            <DeleteIcon />
          </IconButton>
        ) : null
      }
      {
        isChild ? null : (
          <IconButton onClick={setReplyOpen}>
            <ReplyIcon />
          </IconButton>
        )
      }
    </Stack>
  )
}

function CommentItem(props) {
  const { commentText, commentUserProfile, isChild, isCommentOwner, userImageComponent, parentCommentId, activeUid, bookId, getCommentsRequest, commentId } = props;

  const [childCommentComposerOpen, setChildCommentComposerOpen] = React.useState(false);

  return (
    <>
      <ListItemWithHoverActions
        alignItems="flex-start"
        secondaryAction={
          <CommentActions
            setReplyOpen={() => setChildCommentComposerOpen(true)}
            isChild={isChild}
            isCommentOwner={isCommentOwner}
            commentId={commentId}
            activeUid={activeUid}
            getCommentsRequest={getCommentsRequest}
          />
        }
      >
        <ListItemAvatar>
          {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
          <UserImage firstName={commentUserProfile.firstName} lastName={commentUserProfile.lastName} imageMetadata={commentUserProfile.img} />
        </ListItemAvatar>
        <ListItemText
          primary={getNameFromProfile(commentUserProfile)}
          secondary={
            <Typography
              variant="body2"
              color="text.secondary"
            >
              {commentText}
            </Typography>
          }
        />
      </ListItemWithHoverActions>
      {
        isChild ? null : (
          <Collapse orientation="vertical" in={childCommentComposerOpen}>
            <Box sx={{ display: "flex", flexDirection: "inherit", paddingLeft: "3rem" }}>
              <Divider orientation="vertical" flexItem />
              <CommentComposer
                size="small"
                boxSx={{ paddingLeft: "1rem" }}
                userImageComponent={userImageComponent}
                parentCommentId={parentCommentId}
                activeUid={activeUid}
                bookId={bookId}
                getCommentsRequest={getCommentsRequest}
                collapseChildTextBox={() => setChildCommentComposerOpen(false)}
              />
            </Box>
          </Collapse>
        )
      }
    </>
  )
}

const getParentComments = (comments) => {
  if (!comments) {
    return [];
  }

  return comments.filter((item) => item.parentComment === null);
}

const getChildComments = (comments, parentCommentId, activeUid, userProfile, userImageComponent, getCommentsRequest) => {
  const childComments = comments.filter((item) => item.parentComment === parentCommentId);

  if (childComments.length === 0) {
    return null;
  }

  return childComments.map((item) => {
    return (
      <Box sx={{ display: "flex", flexDirection: "inherit", paddingLeft: "3rem" }}>
        <Divider orientation="vertical" flexItem />
        <CommentItem
          commentText={item.text}
          commentId={item._id}
          commentUserProfile={getProfile(userProfile, item.user)}
          isChild
          isCommentOwner={item.user === activeUid}
          userImageComponent={userImageComponent}
          parentCommentId={parentCommentId}
          activeUid={activeUid}
          getCommentsRequest={getCommentsRequest}
        />
      </Box>
    )
  })

}

const renderComments = (comments, activeUid, userProfile, userImageComponent, bookId, getCommentsRequest) => {
  const parentComments = getParentComments(comments);

  if (parentComments.length === 0) {
    return <FullPageNoDiscussionsNotice />;
  } 
  
  return parentComments.map((item) => {
    return (
      <Box>
        <CommentItem
          commentText={item.text}
          commentUserProfile={getProfile(userProfile, item.user)}
          isCommentOwner={item.user === activeUid}
          parentCommentId={item._id}
          commentId={item._id}
          userImageComponent={userImageComponent}
          activeUid={activeUid}
          bookId={bookId}
          getCommentsRequest={getCommentsRequest}
        />
        {getChildComments(comments, item._id, activeUid, userProfile, userImageComponent, getCommentsRequest)}
      </Box>
    )
  });
}

export default function CommentItems(props) {
  const { comments, activeUid, userProfile, userImageComponent, bookId, getCommentsRequest } = props;

  return (
    <List>
      {renderComments(comments, activeUid, userProfile, userImageComponent, bookId, getCommentsRequest)}
    </List>
  );
}
