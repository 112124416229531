import * as React from "react";

import { useNavigate } from "react-router-dom";
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';;
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import Badge from '@mui/material/Badge';


import { AuthContext } from "../../contexts/authProvider";

const getUserFromUid = (uid, userMetadata) => {
  if (!userMetadata || !uid) {
    return {};
  }
  return userMetadata.find((item) => item.uid === uid);
}

const getActionIcon = (action) => {
  switch (action) {
    case 'like':
      return <FavoriteIcon />;
    case 'follow':
      return <PersonAddAlt1Icon />;
    case 'comment':
      return <ChatBubbleOutlineIcon />;
    default:
      return <NotificationsIcon />;
  }
}

export default function NotifItem(props: Props) {
  const navigate = useNavigate();

  const { action, actorUid, highlightMetadata, unread, userMetadata, handlePopoverClose, comments } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState(null);

  const { user, handleSetUserDetails } = React.useContext(AuthContext);
  const uid = user.firebaseData.auth.currentUser.uid;


  let userItrMetadata = getUserFromUid(actorUid, userMetadata);

  const getNotifTitle = (action) => {
    switch (action) {
      case 'like':
        return (
          <Typography variant="body1">{`${userItrMetadata.firstName} has liked your highlight`}</Typography>
        )
      
      case 'follow':
        return (
          <Typography variant="body1">{`${userItrMetadata.firstName} is now following you`}</Typography>
        )
      
      case 'comment':
        return (
          <Typography variant="body1">{`${userItrMetadata.firstName} responded to your discussion point`}</Typography>
        )

    }
  }

  const getNotifSubtitle = (action, highlightMetadata, comments) => {
    switch (action) {
      case 'like':
        if (highlightMetadata.length > 0) {
          return (
            <Typography variant="subtitle2" sx={{ color: "gray", fontStyle: "italic", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{highlightMetadata[0]['highlights']}</Typography>
          )
        } else {
          return (
            <Typography variant="subtitle2" sx={{ color: "gray", fontStyle: "italic", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
              This highlight no longer exists on Blipps.io
            </Typography>
          )
        }
      
      case 'follow':
        return (
          <Typography variant="subtitle2" sx={{ color: "gray", fontStyle: "italic", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>Click to explore their profile</Typography>
        )
      
      case 'comment':
        return (
          <Typography variant="subtitle2" sx={{ color: "gray", fontStyle: "italic", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{comments ? comments[0] : null}</Typography>
        )

    }
  }

  const getNotifAction = (action, highlightMetadata, comments) => {
    switch (action) {
      case 'like':
        handlePopoverClose();
        return (
          navigate(`/library/item/${highlightMetadata[0]['title']}`)
        );
        
      case 'follow':
        handlePopoverClose();
        return (
          navigate(`/library/${userItrMetadata.uid}`)
          );
      
      case 'comment':
        handlePopoverClose();
        return (
          navigate(`/library/item/${comments ? comments[1] : null}`)
        );
    }
  }

  return (
    <ListItemButton
      onClick={() => getNotifAction(action, highlightMetadata, userMetadata, comments)}
      // key={item.uid}
    >
      {/* {getBookImage(item)} */}
      <ListItemAvatar>
        <Badge color="secondary" variant="dot" invisible={!unread}>
          <Avatar>
            {getActionIcon(action)}
          </Avatar>
        </Badge>
      </ListItemAvatar>
      <ListItemText primary={getNotifTitle(action, actorUid, userMetadata)} secondary={getNotifSubtitle(action, highlightMetadata, comments)} />
    </ListItemButton>
  );
}
