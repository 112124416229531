import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import FormHelperText from '@mui/material/FormHelperText';

import { AuthContext } from "../../contexts/authProvider";

import { getUserdata } from "../../api/resources/user";
import { postHighlights, getPublicHighlights } from "../../api/resources/library";

export default function NewHighlightsDialog(props) {
  const { open, handleClose, existingHighlight, existingTitle, existingAuthor, activeUid, otherUsers } = props;
  const { user, handleSetUserDetails } = React.useContext(AuthContext);


  const [highlight, setHighlight] = React.useState(existingHighlight);
  const [title, setTitle] = React.useState(existingTitle);
  const [author, setAuthor] = React.useState(existingAuthor);
  const [formError, setFormError] = React.useState('');

  const handleSubmit = async () => {
    if (!highlight || !title || !author) {
      setFormError('One or more fields are missing');

      return false;
    }

    if (highlight.length < 1 || title.length < 1 || author.length < 1) {
      setFormError('One or more fields are missing');

      return false;
    } 

    if (postHighlights(activeUid, title, author, highlight)) {
      handleClose();
      if (otherUsers) {
        const res = await getPublicHighlights(activeUid, activeUid);
        const userdataRes = await getUserdata(activeUid);
        
        handleSetUserDetails(userdataRes);
        handleSetHighlights(res);
      } else {
        const userdataRes = await getUserdata(activeUid);

        handleSetUserDetails(userdataRes);
      }
      return true;
    } else {
      setFormError('An error occurred trying to store the highlight, please try again later.');
      return false;
    }


    return true;
  }


  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>Add Highlight</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Blipps works better when you import highlights from a supported eReader. However, you can still add your highlights manually.
        </DialogContentText>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Highlight"
              type="text"
              fullWidth
              variant="outlined"
              rows={3}
              required
              multiline
              defaultValue={existingHighlight}
              onChange={(e) => setHighlight(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              id="name"
              label="Title"
              type="text"
              fullWidth
              required
              variant="outlined"
              defaultValue={existingTitle}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              id="name"
              label="Author"
              type="text"
              fullWidth
              required
              variant="outlined"
              defaultValue={existingAuthor}
              onChange={(e) => setAuthor(e.target.value)}
            />
          </Grid>
        </Grid>
        { formError.length > 0 ? <FormHelperText error>{formError}</FormHelperText> : null }
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => handleClose()}>Cancel</Button>
        <Button color="primary" onClick={() => handleSubmit()}>Add</Button>
      </DialogActions>
    </Dialog>
  );
}