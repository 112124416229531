import * as React from "react";

import { useNavigate } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import Box from "@mui/material/Box";
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Grid from "@mui/material/Grid";

import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import MenuBookIcon from '@mui/icons-material/MenuBook';

import LoadingIndicator from "../LoadingIndicator";

import { AuthContext } from "../../contexts/authProvider";

import { getSearch } from "../../api/resources/utilities";

import { getUid } from "../../processes/user";

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha("rgba(0, 0, 0, 0.54)", 0.35),
  '&:hover': {
    backgroundColor: alpha("rgba(0, 0, 0, 0.54)", 0.45),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const UnauthStyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '30ch',
      '&:focus': {
        width: '60ch',
      },
    },
  },
}));

export default function SearchBar(props: Props) {
  const navigate = useNavigate();
  const { isUnauth } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUserImage = (item) => {
    function stringToColor(string: string) {
      let hash = 0;
      let i;
    
      /* eslint-disable no-bitwise */
      for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }
    
      let color = '#';
    
      for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
      }
      /* eslint-enable no-bitwise */
    
      return color;
    }
    
    const stringAvatar = (name: string) => {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      };
    }

    const getName = () => {
      if (item) {
        let firstName = item.firstName;
        let lastName = item.lastName;

        if (firstName) {
          firstName = firstName.trim();
        } else {
          firstName = "";
        }

        if (lastName) {
          lastName = lastName.trim();
        } else {
          lastName = "";
        }

        return firstName + " " + lastName;
      }
      return "The Nameless";
    }

    if (item && item.img) {
      const image = item.img.img;

      return (
        <Box
          component="img"
          src={`data:${item.img.contentType};base64, ${item.img.img}`}
          sx={{
            borderRadius: "50%",
            //border: "1px dashed grey",
            width: "3.5rem",
            height: "3.5rem",
          }}
        />
      )
    }

    return <Avatar {...stringAvatar(getName())} />;
  }

  const getBookImage = (item) => {
    if (item && item.image) {
      return (
        <ListItemAvatar>
          <Box component="img" src={item.image} sx={{ maxWidth: "2.7rem", borderRadius: "7px" }} />
        </ListItemAvatar>
      )
    } else {
      return (
        <ListItemIcon>
          <MenuBookIcon />
        </ListItemIcon>
      )
    }
    // console.log(item)
    function stringToColor(string: string) {
      let hash = 0;
      let i;
    
      /* eslint-disable no-bitwise */
      for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }
    
      let color = '#';
    
      for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
      }
      /* eslint-enable no-bitwise */
    
      return color;
    }
    
    const stringAvatar = (name: string) => {
      return {
        sx: {
          bgcolor: stringToColor(name),
          width: "7rem",
          height: "7rem",
          margin: "0 auto",
          fontSize: "2rem",
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      };
    }

    // if (user.userDetails) {
    //   if (Object.prototype.hasOwnProperty.call(user.userDetails, "userinfo")) {
    //     if (user.userDetails["userinfo"][0]["img"]) {
    //       if (user.userDetails["userinfo"][0]["img"]["contentType"].includes("image/")) {
    //         const imageDetails = user.userDetails["userinfo"][0]["img"];

    //         return (
    //           <Box
    //             component="img"
    //             src={`data:${imageDetails.contentType};base64, ${imageDetails.img}`}
    //             sx={{
    //               borderRadius: "50%",
    //               //border: "1px dashed grey",
    //               width: "7rem",
    //               height: "7rem",
    //             }}
    //           />
    //         )
    //       }
    //     }
    //   }
    // }

    // return <Avatar {...stringAvatar(getName())} />;
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState(null);

  const { user, handleSetUserDetails } = React.useContext(AuthContext);

  const uid = getUid(user.firebaseData, isUnauth);

  const handleSearch = async (event) => {
    if (!event.target.value || event.target.value.length < 3) {
      setSearchResults("tooshort")
      handlePopoverOpen(event)
      return false;
    }

    setSearchResults(null)
    handlePopoverOpen(event)

    const res = await getSearch(uid, event.target.value);

    setSearchResults(res)
  }

  const getUserResults = () => {
    if (searchResults && searchResults.users.length === 0) {
      return (
        <Box>
          <Typography sx={{ fontStyle: "italic" }}>No User Results</Typography>
        </Box>
      )
    }

    return searchResults.users.map((item) => (
      <ListItemButton
        onClick={() => {
          setAnchorEl(null);
          setSearchResults(null);
          navigate(`/library/${item.uid}`);
        }}
        key={item.uid}
      >
        <ListItemAvatar>
          {getUserImage(item)}
        </ListItemAvatar>
        <ListItemText primary={item.firstName + " " + item.lastName} secondary="A Fellow Blipps User" />
      </ListItemButton>
    ))
  }

  const getBookResults = () => {
    if (searchResults && searchResults.books.length === 0) {
      return (
        <Box>
          <Typography sx={{ fontStyle: "italic" }}>No Book Results</Typography>
        </Box>
      )
    }

    return searchResults.books.map((item) => (
      <ListItemButton
        onClick={() => {
          setAnchorEl(null);
          setSearchResults(null);
          navigate(`/library/item/${item.title}`);
        }}
        key={item.uid}
      >
        {getBookImage(item)}
        <ListItemText primary={item.title} secondary={
          <>
            <Typography>{item.subtitle}</Typography>
            <Typography sx={{ fontStyle: "italic" }}>{item.author}</Typography>
          </>
        } />
      </ListItemButton>
    ))
  }

  const getSearchPopoverContent = () => {
    if (!searchResults) {
      return (
        <>
          <Box sx={{ maxWidth: "17rem", alignSelf: "center" }}>
            <LoadingIndicator />
          </Box>
          <Typography sx={{ fontSize: "1.4rem", fontStyle: "italic", fontFamily: "Lora, serif" }}>Hold on tight while we get your search results</Typography>
        </>
      )
    }

    if (searchResults && searchResults === "tooshort") {
      return (
        <Box sx={{ display: "flex", flexDirection: "column", textAlign: "center", minWidth: "320px" }}>
          <Typography sx={{ fontSize: "1.4rem", fontStyle: "italic", fontFamily: "Lora, serif" }}>Search is too short</Typography>
        </Box>
      )
    }

    if (searchResults && searchResults.length === 0) {
      return (
        <Box sx={{ display: "flex", flexDirection: "column", textAlign: "center", minWidth: "320px" }}>
          <Typography sx={{ fontSize: "1.4rem", fontStyle: "italic", fontFamily: "Lora, serif" }}>Search returned no results</Typography>
        </Box>
      )
    }

    if (searchResults) {
      return (
        <Grid container>
          <Grid xs={12} sm={6}>
            <Typography variant="h5">Users</Typography>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {getUserResults()}
            </List>
          </Grid>
          <Grid xs={12} sm={6}>
            <Typography variant="h5">Books</Typography>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {getBookResults()}
            </List>
          </Grid>
        </Grid>
      )
    }
  }

  return (
    <>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        {
          isUnauth ? (
            <UnauthStyledInputBase
              {...props}
              placeholder="Search"
              inputProps={{ 'aria-label': 'search' }}
              onChange={(event) => handleSearch(event)}
            />
          ) : (
            <StyledInputBase
              {...props}
              placeholder="Search"
              inputProps={{ 'aria-label': 'search' }}
              onChange={(event) => handleSearch(event)}
            />
          )
        }
      </Search>
      <Popover
        disableAutoFocus
        disableEnforceFocus
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: isUnauth ? 'center' : 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: isUnauth ? 'center' : 'right',
        }}
        PaperProps={{
          sx: { width: '100%', height: { xs: "50%", md: "369px" }, borderRadius: "20px", marginTop: "0.5rem", maxWidth: { xs: "93%", md: "880px" } },
        }}
      >
        <Grid container sx={{ maxWidth: "880px", maxHeight: "500px" }}>
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", textAlign: "center", margin: "1rem" }}>
            {getSearchPopoverContent()}
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}
