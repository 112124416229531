import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';

import noCover from "../../assets/img/nocover.png";
import { useNavigate } from "react-router-dom";
import Likers from "../Likers";

export default function HighlightsCardPlain(props) {
  const { highlightId, details, highlights, activeUid, highlightCreatorUserId, likers, creatorProfiles, likerProfiles, isBookClub } = props;

  const navigate = useNavigate();

  const getCreatorProfile = (uid) => {
    if (!creatorProfiles) {
      return null;
    }

    const arrProfile = creatorProfiles.find((item) => item ? item.uid === uid : null);

    if (arrProfile) {
      return arrProfile;
    } else {
      return null;
    }
  }

  const getLikerProfile = (uid) => {
    const arrProfile = likerProfiles.find((item) => item.uid === uid);

    if (arrProfile) {
      return arrProfile;
    } else {
      return null;
    }
  }

  const getLikers = () => {
    if (likers && likers.length > 0) {
      return (
        <Likers {...props} />
      )
    }

    return (
      <Likers {...props} emptyLikes />
    )
  }

  const getHighlightCreator = () => {
    if (isBookClub) {
      const profile = getCreatorProfile(highlightCreatorUserId);

      return (
        <Grid item xs={12}>
          { highlightCreatorUserId !== activeUid ? (
            <Typography
              component="span"
              onClick={() => navigate(`/library/${highlightCreatorUserId}`)}
              sx={{ fontSize: 14, fontWeight: "bold", cursor: "pointer" }}
              color="text.secondary"
            >
              {profile ? profile.firstName : null}
            </Typography>
          ) : "You" }
          {` added a highlight`}
        </Grid>
      )
    }
    
    return null;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Card
        raised
        variant="outlined"
        sx={{ borderRadius: "20px", boxShadow: 3, display: "flex" }}
      >
        <CardContent sx={{ width: "100%" }}>
          <Grid spacing={1} container>
            {getHighlightCreator()}
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    mb: 1.5,
                    textAlign: "center",
                    fontFamily: "Lora",
                    fontSize: "1.5rem",
                    margin: "auto",
                  }}
                  variant="body2"
                >
                  {`"${highlights}"`}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography
                  sx={{ fontSize: 14, textAlign: "right" }}
                  color="text.secondary"
                >
                  {details}
                </Typography>
              </Box>
            </Grid>
            {getLikers()}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}
