import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';


import screen_001 from '../../assets/img/tutorials/kobo_image_1.png';
import screen_002 from '../../assets/img/tutorials/kobo_image_2.png';
import screen_003 from '../../assets/img/tutorials/kobo_image_3.png';

const steps = [
  {
    label: 'Connect Kobo to your computer, you may need to tap “Connect” on your Kobo device',
    description: () => {
      return (
        <Box>
          <Box component="img" src={screen_001} sx={{ width: "100%", maxWidth: "420px", border: "1px solid #555" }} />
        </Box>
      )
    },
  },
  {
    label: 'Look for your Kobo eReader in file explorer (Windows) or Finder (macOS)',
    description: () => {
      return (
        <Box>
          <Box component="img" src={screen_002} sx={{ width: "100%", maxWidth: "220px", border: "1px solid #555" }} />
        </Box>
      )
    },
  },
  {
    label: 'Access the “.kobo” folder and create a copy of “KoboReader.sqlite”, saving it anywhere on your device.',
    description: () => {
      return (
        <Box>
          <Typography sx={{ fontWeight: "bold", border: "1px dotted black" }}>NOTE</Typography>
          <Typography gutterBottom>If you do not see your .kobo folder, you may need to enable display of hidden files and folders.</Typography>
          <Typography gutterBottom><span style={{fontWeight: "bold"}}>On Windows: </span>"Go to View > Options > Change folder and search options. Select the View tab and, in Advanced settings, select Show hidden files, folders, and drives and OK."</Typography>
          <Typography gutterBottom><span style={{fontWeight: "bold"}}>On macOS: </span>Press CMD + Shift + .</Typography>
          <Box component="img" src={screen_003} sx={{ width: "100%", maxWidth: "420px", border: "1px solid #555" }} />
        </Box>
      )
    },
  },
  {
    label: 'Click on the button below to browse for your “KoboReader.sqlite” file then start accessing highlights!',
    description: () => { },
  },
];


const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function KoboStepper(props: Props) {
  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const [activeStep, setActiveStep] = React.useState(0);
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 3 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Box>
                {step.description()}
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
}
