import * as React from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import noCover from "../../assets/img/nocover.png";
import logo from "../../assets/img/logos/logo.png";

const FavouriteCardPlain = React.forwardRef((props, ref) => {
  const { image, highlight, author, title } = props;
//backgroundColor: "white", border: "1px solid black",
  return (
    <Card
      raised
      variant="outlined"
      ref={ref}
      sx={{ boxShadow: 3, display: "flex" }}
    >
      <CardContent>
        <Box sx={{  width: "100%", height: {xs: "100%", sm: "380px"}, maxWidth: "1020px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Grid container sx={{ maxWidth: "100%", padding: "0 1rem" }}>
            {/* <Grid
              item
              xs={12}
              md={3}
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                // crossOrigin="use-credentials"
                component="img"
                sx={{ width: "100%", borderRadius: "10px", maxWidth: "169px",boxShadow: 3 }}
                // src={image ? image : noCover}
              />
            </Grid> */}
            <Grid
              item
              xs={12}
              // md={9}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    mb: 1.5,
                    textAlign: "center",
                    fontFamily: "Lora",
                    fontSize: "1.5rem",
                    margin: "auto",
                  }}
                  variant="body2"
                >
                  {`"${highlight}"`}
                </Typography>
              </Box>
              <Box sx={{ textAlign: "right", marginTop: "2rem" }}>
                <Typography
                  variant="overline"
                  component="div"
                  sx={{ lineHeight: "unset", fontSize: "1.2rem" }}
                >
                  {title}
                </Typography>
                <Typography
                  variant="overline"
                  color="text.secondary"
                  sx={{ lineHeight: "unset" }}
                  >
                  {author}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "row",  justifyContent: "end" }}>
          <Typography sx={{ margin: "auto 0.8rem", fontColor: "grey" }} variant="overline">Highlighted on blipps.io</Typography>
          <Box
            component="img"
            src={logo}
            sx={{ maxWidth: "3rem", borderRadius: "50%" }}
          />
        </Box>
      </CardContent>
    </Card>
  )
});

export default FavouriteCardPlain;
