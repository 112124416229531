import React, { useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { FileUploader } from "react-drag-drop-files";
import { postKindleImport } from "../../api/resources/import";
import { AuthContext } from "../../contexts/authProvider";
import { LibraryContext } from "../../contexts/libraryProvider";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import RefreshIcon from '@mui/icons-material/Refresh';
import Typography from "@mui/material/Typography";
import LoadingButton from '@mui/lab/LoadingButton';

import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";

import { getUserdata } from "../../api/resources/user";
import { getHighlights, getHighlightsCount } from "../../api/resources/library";
import { getTitles } from "../../api/resources/utilities";

const fileTypes = ["TXT"];

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number },
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function FileUploaderContainer() {
  const { user, handleSetUserDetails } = React.useContext(AuthContext);
  const uid = user.firebaseData.auth.currentUser.uid;
  const [file, setFile] = useState(null);
  const { library, handleSetLibrary, handleSetHighlightCounts } = React.useContext(LibraryContext);

  const [progress, setProgress] = React.useState(10);
  const [uploadInProgress, setUploadInProgress] = React.useState(false);
  const [uploadComplete, setUploadComplete] = React.useState(false);
  const [onwardBtnLoading, setOnwardBtnLoading] = React.useState(false);

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (progress === 100) {
        // setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
        setUploadComplete(true);
      } else if (progress >= 70) {
        setProgress((prevProgress) => prevProgress + 1);
      }
    }, 1069);
    return () => {
      clearInterval(timer);
    };
  }, [progress]);

  const getOverlineText = () => {
    if (uploadInProgress && progress <= 75) {
      return "Currently uploading your highlights";
    } else if (uploadInProgress && progress < 100) {
      return "Processing your upload";
    } else if (progress === 100) {
      return "Upload complete, click on the button above to visit your feed!";
    }

    return "";
  };

  const handleChange = async (file) => {
    setFile(file);
    setUploadInProgress(true);

    if (postKindleImport(uid, file)) {
      getTitles();

      setProgress(70);
    }
  };

  const handleRefreshBtn = async () => {
    setOnwardBtnLoading(true);
    const res = await getHighlights(uid);

    const parsedMetadata = {};

    res["bookMetadata"].forEach((item) => {
      if (!item) {
        return;
      }

      parsedMetadata[item.title] = {
        image: item.image,
      };
    });

    handleSetLibrary(
      res.cards,
      parsedMetadata,
      res.likersMetadata,
      res.FavHighlights,
    );

    const res2 = await getHighlightsCount(uid);

    handleSetHighlightCounts(res2.libraryData);

    const getHighlightsRequest = async () => {
      const res = await getUserdata(uid);

      handleSetUserDetails(res);
    };

    getHighlightsRequest();

    setOnwardBtnLoading(false);
  };

  return (
    <Box pt="1.5rem">
      <Box>
        {uploadComplete ? (
          <LoadingButton
            size="large"
            variant="outlined"
            component="label"
            startIcon={<RefreshIcon />}
            onClick={() => handleRefreshBtn()}
            loading={onwardBtnLoading}
          >
            Refresh App
          </LoadingButton>
        ) : (
          <Button
            size="large"
            variant="contained"
            component="label"
            startIcon={<UploadFileIcon />}
            disabled={uploadInProgress}
          >
            Upload Highlights
            <input
              hidden
              accept=".txt,.sqlite"
              type="file"
              onChange={(event) => handleChange(event.target.files[0])}
            />
          </Button>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          maxWidth: "690px",
          margin: "0 auto",
          paddingTop: "1.3rem",
        }}
        hidden={!uploadInProgress && !uploadComplete && progress === 10}
      >
        <LinearProgressWithLabel value={progress} />
        <Typography variant="overline">{getOverlineText()}</Typography>
      </Box>
    </Box>
  );
}
