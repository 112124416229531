import { axiosClient } from "../apiClient";

export async function postKindleImport(uid, file) {
  const formData = new FormData();

  formData.append("uid", uid);
  formData.append("data", file);
  
  try {
    let res = await axiosClient.post("/import", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    // Run to refresh images
    axiosClient.get("/getTitles");

    return true;
  } catch (error) {
    return [];
  }
}
